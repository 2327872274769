import * as V1 from "kmmp/orders/v1";
import * as V2 from "kmmp/orders/v2";
import * as Frontend from "kmmp/frontend";
import { uuid } from "shared/uuid";
import { CreateOrderRequest } from "api";

/**
 * Maps database-style images (camelCased) to request-style images (snake_cased).
 */
export function mapToRequestImage(input: V1.Image | V2.Image): CreateOrderRequest.Image {
    return {
        cropped_image: input.croppedImage
            ? {
                  file_name: input.croppedImage.filename,
                  full_azure_url: input.croppedImage.fullAzureUrl,
                  thumbnail_azure_url: input.croppedImage.thumbnailAzureUrl,
              }
            : null,
        full_image: {
            file_name: input.fullImage.filename,
            full_azure_url: input.fullImage.fullAzureUrl,
            thumbnail_azure_url: input.fullImage.thumbnailAzureUrl,
        },
        special_instructions: input.specialInstructions,
        too_small: input.tooSmall,
        uuid: input.uuid || "",
    };
}

/**
 * Maps request-style images to database-style-images.
 */
export function mapToDatabaseImage(input: CreateOrderRequest.Image): V2.Image {
    return {
        uuid: input.uuid,
        tooSmall: input.too_small,
        specialInstructions: input.special_instructions,
        fullImage: {
            filename: input.full_image.file_name,
            fullAzureUrl: input.full_image.full_azure_url,
            thumbnailAzureUrl: input.full_image.thumbnail_azure_url,
        },
        croppedImage: input.cropped_image ? {
            filename: input.cropped_image.file_name,
            fullAzureUrl: input.cropped_image.full_azure_url,
            thumbnailAzureUrl: input.cropped_image.thumbnail_azure_url,
        } : null
    };
}

/**
 * Maps database images to the image objects used by the frontend.
 */
export function mapToFrontendImage(input: V1.Image | V2.Image): Frontend.UploadedImage {
    return {
        croppedImage: input.croppedImage
            ? {
                  fileName: input.croppedImage.filename,
                  srcUrl: input.croppedImage.fullAzureUrl,
                  thumbnailUrl: input.croppedImage.thumbnailAzureUrl,
              }
            : null,
        fullImage: {
            fileName: input.fullImage.filename,
            srcUrl: input.fullImage.fullAzureUrl,
            thumbnailUrl: input.fullImage.thumbnailAzureUrl,
        },
        instructions: input.specialInstructions || "",
        tooSmall: input.tooSmall,
        uuid: input.uuid || uuid(),
    };
}
