import * as React from "react";
import * as Frontend from "kmmp/frontend";
import { Option } from "@nozzlegear/railway";
import { getFrames, getFrameUrl, getPrestigePortraitFrameImages } from "shared/images";
import { WhitelabelSettings } from "kmmp";
import { PortraitPreview } from "client/components/portrait_preview";
import { useWhitelabel } from "client/hooks";

type Props = {
    onSelectFrame: (frame: Frontend.FrameImage) => void;
    frame: Option<Frontend.FrameImage>;
    background: Frontend.BackgroundImage;
    orientation: Option<Frontend.Orientation>;
    whitelabelSettings: WhitelabelSettings;
    displayAsPackage: boolean;
    isSignaturePortrait: boolean;
}

/**
 * Component for selecting a frame variant by clicking on an image. Also displays the selected frame variant.
 */
export function VisualFrameSelector(props: Props): JSX.Element {
    const { companyName } = useWhitelabel();
    const validFrames = React.useMemo(() => {
        return props.isSignaturePortrait === true
            ? getPrestigePortraitFrameImages(companyName)
            : getFrames(companyName);
    }, [props.isSignaturePortrait, companyName]);

    const previewImage = (variant: Frontend.FrameImage) => (event: React.MouseEvent<any>) => {
        event.preventDefault();
        props.onSelectFrame(variant);
    };

    const images = validFrames.map((frame) => (
        <div key={frame.name} className="pure-u-sm-12-24 pure-padded">
            <a href="#" key={frame.name} onClick={previewImage(frame)}>
                <img
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    src={getFrameUrl(
                        props.whitelabelSettings.companyName,
                        props.whitelabelSettings.subjectType,
                        frame,
                        "portrait",
                        "16x20"
                    )}
                    className="img-responsive"
                />
            </a>
        </div>
    ));

    return (
        <div className="pure-g justify-space-between">
            <div className="pure-u-sm-visible pure-u-sm-9-24">
                <div className="thumbnails">{images}</div>
            </div>
            <div className="pure-u-sm-visible pure-u-sum-1-24" />
            <div className="pure-u-1 pure-u-sm-14-24">
                <PortraitPreview
                    background={props.background}
                    frame={props.frame.defaultValue(validFrames[0])}
                    size="16x20"
                    orientation={props.orientation.defaultValue("portrait")}
                    displayAsPackage={props.displayAsPackage}
                    whitelabelSettings={props.whitelabelSettings}
                    isSignaturePortrait={props.isSignaturePortrait}
                />
            </div>
        </div>
    );
}
