import * as React from "react";
import * as Stores from "client/stores";
import { Paths } from "shared/paths";
import { useLocation } from "wouter";
import { useUnauthorizedPrompt, useState } from "client/hooks";
import { uuid } from "shared/uuid";
import PageHeader from "client/components/page-header";
import { Auth } from "client/stores";
import { Option } from "@nozzlegear/railway";
import { EngravingControls } from "./engraving_controls";
import { VisualSignatureJerseySelector } from "./visual_signature_jersey_selector";
import { QuantitySelector } from "./quantity_selector";
import { PurchaseOrderIdBox } from "./purchase_order_id_box";
import { purchaseOrderIdIsValid } from "shared/purchase-order-id-is-valid";
import { SignatureJerseyFrame, SignatureJerseyPackageType } from "kmmp/frontend";
import * as packageConversion from "shared/package-types/signature-jerseys";

type Props = React.ClassAttributes<{}>;

interface State {
    packageType: SignatureJerseyPackageType;
    frame: SignatureJerseyFrame;
    purchaseOrderId: string;
    quantity: number;
    engravingName: string;
    engravingDate1: string;
    engravingDate2: string;
    engravingSentiment: string;
    error: Option<string>;
}

export function SignatureJerseysPage(props: Props): JSX.Element {
    const [_, setLocation] = useLocation();
    const handleUnauthorized = useUnauthorizedPrompt();
    const [state, setState] = useState<State>({
        packageType: "SJ1",
        frame: "Black",
        purchaseOrderId: "",
        quantity: 1,
        engravingName: "",
        engravingDate1: "",
        engravingDate2: "",
        engravingSentiment: "",
        error: Option.ofNone(),
    });
    const isMobile = window.innerWidth < 767;

    const setPurchaseOrderId: (value: string) => void = (value) => setState({ purchaseOrderId: value });

    const setPackageType: (value: SignatureJerseyPackageType) => void = (value) => setState({ packageType: value });

    const setQuantity: (value: number) => void = (value) => setState({ quantity: value });

    const setFrame: (value: SignatureJerseyFrame) => void = (value) => setState({ frame: value });

    const setEngravingName = (value: string) => setState({ engravingName: value });

    const setEngravingDate1 = (value: string) => setState({ engravingDate1: value });

    const setEngravingDate2 = (value: string) => setState({ engravingDate2: value });

    const setEngravingSentiment = (value: string) => setState({ engravingSentiment: value });

    const addToCart: () => void = () => {
        if (Stores.Auth.requireCoupaPurchaseOrder && !state.purchaseOrderId) {
            setState({
                error: Option.ofSome(
                    "Your location is part of our HMIS+ integration. Please enter a valid Purchase Order ID from HMIS+."
                ),
            });
            return;
        } else if (Stores.Auth.requireCoupaPurchaseOrder && !purchaseOrderIdIsValid(state.purchaseOrderId)) {
            setState({
                error: Option.ofSome("You must enter a valid Purchase Order ID."),
            });
            return;
        } else if (state.quantity < 1 || state.quantity > 10) {
            setState({
                error: Option.ofSome("You must select a quantity between 1 and 10."),
            });
            return;
        }

        Stores.Cart.addToCart({
            uuid: uuid(),
            type: "signature-jersey",
            customization: {
                frame: state.frame,
                package: "SJ1",
                engravingName: state.engravingName || null,
                engravingDate1: state.engravingDate1 || null,
                engravingDate2: state.engravingDate2 || null,
                engravingSentiment: state.engravingSentiment || null,
            },
            qty: {
                packages: state.quantity,
            },
            source: "quick",
            purchaseOrderId: state.purchaseOrderId,
            deceasedName: "",
            originalOrderId: null,
        });

        setLocation(Stores.Auth.companySettings.siteType === "ecommerce" ? Paths.cart.index : Paths.cart.name);
    };

    return (
        <section id="cart">
            <PageHeader title={`Add Prestige Jersey to Cart`} />
            <div className="section white pure-g center-children">
                <div className="pure-u-22-24">
                    <div className="pure-u-13-24 col-sm-pull-5">
                        <VisualSignatureJerseySelector
                            whitelabelSettings={Auth.companySettings}
                            frame={state.frame}
                            onSelectFrame={setFrame}
                            displayAsPackage={false}
                        />
                    </div>
                    <div className="pure-u-2-24" />
                    <div className="pure-u-9-24 col-sm-push-7">
                        <div className="product main" itemScope={true} itemType="http://schema.org/Product">
                            <h1 className="heading" itemProp="name">
                                {"Prestige Jersey"}
                            </h1>
                            {/*{Auth.companySettings.siteType === "corporate" ? null : (
                                <div>
                                    <hr style={{ margin: "25px 0" }} />
                                    <p>{Auth.companySettings.products[0].description}</p>
                                </div>
                            )}*/}
                            <hr style={{ margin: "25px 0" }} />
                            <Controls
                                packageType={state.packageType}
                                quantity={state.quantity}
                                purchaseOrderId={state.purchaseOrderId}
                                engravingName={state.engravingName}
                                engravingDate1={state.engravingDate1}
                                engravingDate2={state.engravingDate2}
                                engravingSentiment={state.engravingSentiment}
                                error={state.error}
                                onSetEngravingName={setEngravingName}
                                onSetEngravingDate1={setEngravingDate1}
                                onSetEngravingDate2={setEngravingDate2}
                                onSetEngravingSentiment={setEngravingSentiment}
                                onSelectPackageType={setPackageType}
                                onSetQuantity={setQuantity}
                                onClickContinue={addToCart}
                                onSetPurchaseOrderId={setPurchaseOrderId}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

interface ControlsProps extends React.Props<any> {
    quantity: number;
    packageType: SignatureJerseyPackageType;
    purchaseOrderId: string;
    engravingName: string;
    engravingDate1: string;
    engravingDate2: string;
    engravingSentiment: string;
    onSetEngravingName: (newValue: string) => void;
    onSetEngravingDate1: (newValue: string) => void;
    onSetEngravingDate2: (newValue: string) => void;
    onSetEngravingSentiment: (newValue: string) => void;
    onSetQuantity: (newValue: number) => void;
    onSelectPackageType: (size: SignatureJerseyPackageType) => void;
    onSetPurchaseOrderId: (id: string) => void;
    onClickContinue: () => void;
    error: Option<string>;
}

function Controls(props: ControlsProps): JSX.Element {
    const sizes: Array<"24x36"> = ["24x36"];
    const selectedSize = packageConversion.getFrameSizeFromPackage(props.packageType);
    const changeSize = (e: React.FormEvent<HTMLSelectElement>) => {
        const value = e.currentTarget.value as "24x36";
        const packageType = packageConversion.getPackageFromFrameSize(value);

        props.onSelectPackageType(packageType);
    };
    const onContinue = (e: React.MouseEvent<any>) => {
        e.preventDefault();
        props.onClickContinue();
    };

    return (
        <div key={"all-controls"}>
            <div key={"generic-form-controls"}>
                {Stores.Auth.requireCoupaPurchaseOrder ? (
                    <PurchaseOrderIdBox value={props.purchaseOrderId} onChange={props.onSetPurchaseOrderId} />
                ) : null}
                <div className="selector-container">
                    <p>{"Frame Size"}</p>
                    <select className="form-control selector" value={selectedSize} onChange={changeSize}>
                        {sizes.map((size) => (
                            <option key={size} value={size}>
                                {size}
                            </option>
                        ))}
                    </select>
                </div>
                <EngravingControls
                    forSignaturePortraits={false}
                    name={props.engravingName}
                    date1={props.engravingDate1}
                    date2={props.engravingDate2}
                    sentiment={props.engravingSentiment}
                    onNameChanged={props.onSetEngravingName}
                    onDate1Changed={props.onSetEngravingDate1}
                    onDate2Changed={props.onSetEngravingDate2}
                    onSentimentChanged={props.onSetEngravingSentiment}
                />
                <QuantitySelector
                    min={1}
                    max={10}
                    quantity={props.quantity}
                    onChange={props.onSetQuantity}
                    leftLabel={"Quantity"}
                    rightLabel={Option.ofNone()}
                />
                <div className="selector-container">
                    {props.error
                        .map((str) => <p className="error text-center padTop0">{str}</p>)
                        .defaultWith(() => (
                            <p>{String.fromCharCode(160)}</p>
                        ))}
                    <div className="add-to-cart">
                        <button type="button" className="btn blue" onClick={onContinue}>
                            {"Continue To Checkout"}
                        </button>
                    </div>
                </div>
            </div>
            <hr className="marBottom25" />
        </div>
    );
}
