export const Paths = {
    home: {
        index: "/",
        guide: "/guide",
    },
    history: {
        index: "/history",
    },
    cart: {
        index: "/cart",
        name: "/cart/name",
        shipping: "/cart/shipping",
        details: "/cart/details",
    },
    checkout: {
        index: "/checkout",
    },
    confirmed: {
        index: "/confirmed",
    },
    admin: {
        index: "/admin",
        orderHistory: "/admin/history",
        userManagement: "/admin/users",
        announcements: "/admin/announcements",
    },
    auth: {
        login: "/auth/login",
        logout: "/auth/logout",
    },
    quick: {
        index: "/quick",
        signaturePortraits: "/quick/signature-portraits",
        shadowBoxes: "/quick/shadow-boxes",
        signatureJerseys: "/quick/signature-jerseys",
        sampleFrames: "/quick/sample-frames",
    },
    resub: {
        index: "/resub",
    },
    products: {
        index: "/products",
        product: {
            splat: "/products/:id",
            get: (productId: string) => "/products/" + productId,
        },
    },
};

export default Paths;

/**
 * Returns a regex for the given path. Should be passed a path from the default module export, not a user-input path.
 */
export function getPathRegex(path: string) {
    let output: RegExp;

    switch (path) {
        default:
            throw new Error("Given path does not have a known regex.");

        case Paths.cart.index:
            output = /\/cart$/i;
            break;

        case Paths.cart.name:
            output = /\/cart\/name$/i;
            break;

        case Paths.cart.shipping:
            output = /\/cart\/shipping$/i;
            break;

        case Paths.cart.details:
            output = /\/cart\/details$/i;
            break;

        case Paths.confirmed.index:
            output = /\/confirmed$/i;
            break;

        case Paths.auth.login:
            output = /\/auth\/login$/i;
            break;

        case Paths.auth.logout:
            output = /\/auth\/logout$/i;
            break;

        case Paths.quick.index:
            output = /\/quick\/?$/i;
    }

    return output;
}

//Quick validation for the developer to ensure all /cart paths have a regex
Object.keys(Paths.cart)
    .map((key) => Paths.cart[key])
    .forEach((path) => getPathRegex(path));
