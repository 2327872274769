import classes = require("classnames");
import * as React from "react";
import Paths from "shared/paths";
import { Cart, Auth } from "../stores";
import { Link as BaseLink, LinkProps, useRoute } from "wouter";
import { observer } from "mobx-react";
import MediaQuery from "react-responsive";
import { MdShoppingCart as ShoppingCart, MdMenu as Menu } from "react-icons/md";
import { compute } from "@nozzlegear/railway";
import { WhitelabelSettings } from "kmmp";

export interface IProps extends React.Props<any> {
    whitelabelSettings: WhitelabelSettings;
}

export interface NavbarState {
    mobileNavOpen: boolean;
}

function SmallScreen(props: React.Props<any> & { className?: string }) {
    return (
        <MediaQuery maxWidth={959}>
            <div className={props.className}>{props.children}</div>
        </MediaQuery>
    );
}

function LargeScreen(props: React.Props<any> & { className?: string }) {
    return (
        <MediaQuery minWidth={960}>
            <div className={props.className}>{props.children}</div>
        </MediaQuery>
    );
}

function Link(props: LinkProps): JSX.Element {
    const [isActive] = useRoute(props.to || props.href || "");
    const className = (isActive ? "active " : "") + (props.className || "");

    return (
        <BaseLink {...props}>
            <a className={className}>{props.children}</a>
        </BaseLink>
    );
}

@observer
export class Navbar extends React.Component<IProps, Partial<NavbarState>> {
    public state: NavbarState = {
        mobileNavOpen: false,
    };

    public componentDidMount() {
        // const nav = responsiveNav("#collapsible", {
        //     animate: false,
        //     label: "Menu", // String: Label for the navigation toggle
        //     insert: "before", // String: Insert the toggle before or after the navigation
        //     customToggle: "toggler", // Selector: Specify the ID of a custom toggle
        //     closeOnNavClick: true, // Boolean: Close the navigation when one of the links are clicked
        //     openPos: "relative", // String: Position of the opened nav, relative or static
        //     navClass: "nav-collapse", // String: Default CSS class. If changed, you need to edit the CSS too!
        //     navActiveClass: "jsnavactive", // String: Class that is added to <html> element when nav is active
        //     jsClass: "js", // String: 'JS enabled' class which is added to <html> element
        //     init: function () { }, // Function: Init callback
        //     open: function () { }, // Function: Open callback
        //     close: function () { } // Function: Close callback
        // });
    }

    public componentDidUpdate() {}

    private toggleMobileNav = (e: React.SyntheticEvent) => {
        e.preventDefault();

        this.setState({ mobileNavOpen: !this.state.mobileNavOpen });
    };

    public render() {
        const { whitelabelSettings } = this.props;
        const brandLink = (
            <Link to={Paths.home.index} className="brand-link">
                {whitelabelSettings.siteName}
            </Link>
        );
        const leftLinks = compute<JSX.Element[]>(() => {
            if (whitelabelSettings.siteType === "corporate") {
                const links = [
                    <div key={`guide-link`}>
                        <Link to={Paths.home.guide}>{"Guide"}</Link>
                    </div>,
                    <div key={`quick-portrait-link`}>
                        <Link to={Paths.quick.index}>{"Canvas Portraits"}</Link>
                    </div>,
                ];

                if (Auth.companySettings.companyName === "sci") {
                    links.push(
                        <div key={"sample-frames-link"}>
                            <Link to={Paths.quick.sampleFrames}>{"Sample Frames"}</Link>
                        </div>
                    )
                }

                if (Auth.canPurchasePilotProducts) {
                    links.push(
                        <div key={"signature-portraits-link"}>
                            <Link to={Paths.quick.signaturePortraits}>{"Prestige Portraits"}</Link>
                        </div>
                    );
                }

                links.push(
                    <div key={`history-link`}>
                        <Link to={Paths.history.index}>{"Order History"}</Link>
                    </div>
                );

                if (Auth.isAdmin()) {
                    links.push(
                        <div key={"admin-users"}>
                            <Link to={Paths.admin.userManagement}>{"Admin Users"}</Link>
                        </div>
                    );

                    links.push(
                        <div key={"admin-orders"}>
                            <Link to={Paths.admin.orderHistory}>{"Admin Orders"}</Link>
                        </div>
                    );

                    links.push(
                        <div key={"admin-announcements"}>
                            <Link to={Paths.admin.announcements}>{"Admin Announcements"}</Link>
                        </div>
                    );
                }

                return links;
            }

            // Return ecommerce links
            return [
                <div key={`product-list`}>
                    <Link to={Paths.products.index}>
                        {whitelabelSettings.subjectType === "human" ? "Canvas Portraits" : "Canvas Pet Portraits"}
                    </Link>
                </div>,
            ];
        });
        const rightLinks = compute<JSX.Element[]>(() => {
            if (whitelabelSettings.siteType === "corporate") {
                return [
                    <div key="sign-out-button">
                        <Link to={Paths.auth.logout} className="btn btn-default">
                            {"Sign Out"}
                        </Link>
                    </div>,
                ];
            }

            const totalInCart = Cart.items.length;

            return [
                <div id="cartButton" key="cart-button">
                    <Link to={Paths.cart.index} className="btn blue">
                        <ShoppingCart color={`#fff`} size={18} className={`valign-sub left`} />
                        {" Your Shopping Cart "}
                        {`(${totalInCart < 1 ? "empty" : totalInCart})`}
                    </Link>
                </div>,
            ];
        });

        return (
            <nav className="navboi">
                <div className="pure-g">
                    <div className="pure-u-16-24 left-container">
                        <menu className="left">
                            {brandLink}
                            <LargeScreen className="link-container">{leftLinks}</LargeScreen>
                        </menu>
                    </div>
                    <div className="pure-u-8-24 right-container">
                        <menu className="right">
                            <LargeScreen className="link-container">{rightLinks}</LargeScreen>
                            <SmallScreen className="mobile-nav-button">
                                <a
                                    href="#"
                                    className="mobile-nav-button notice me senpai"
                                    onClick={this.toggleMobileNav}
                                >
                                    <Menu size={20} />
                                </a>
                            </SmallScreen>
                        </menu>
                    </div>
                </div>
                <SmallScreen
                    className={classes("mobile-nav-links", "link-container", {
                        open: this.state.mobileNavOpen,
                    })}
                >
                    {leftLinks}
                    {rightLinks}
                </SmallScreen>
            </nav>
        );
    }
}
