import * as React from "react";
import * as Stores from "client/stores";
import * as Frontend from "kmmp/frontend";
import { useState, useMemo } from "react";
import { useLocation } from "wouter";
import { useWhitelabel } from "client/hooks";
import { Paths } from "shared/paths";
import PageHeader from "client/components/page-header";
import { uuid } from "shared/uuid";
import { Option } from "@nozzlegear/railway";
import { QuantitySelector } from "./quantity_selector";
import { PurchaseOrderIdBox } from "./purchase_order_id_box";
import { purchaseOrderIdIsValid } from "shared/purchase-order-id-is-valid";
import { useLoadingState } from "client/hooks/use-loading-state";
import { getSampleFrames } from "shared/images";
import { Repeater } from "shared/components";
import { MaybeError } from "shared/components/maybe-error";
import { ExhaustiveCheckError } from "shared/errors";
import { VisualSampleFrameSelector } from "./visual_sample_frame_selector";

export function SampleFramesPage(): React.ReactElement {
    const [_, setLocation] = useLocation();
    const [{error}, setLoadingState] = useLoadingState(false);
    const [frame, setFrame] = useState<Frontend.SampleFrameName>("Black");
    const [type, setType] = useState<Frontend.SampleFrameCustomization["type"]>("frames-only");
    const [purchaseOrderId, setPurchaseOrderId] = useState("");
    const [quantity, setQuantity] = useState<Frontend.SampleFrameQuantities>({});

    const onSetQuantity = (frame: Frontend.SampleFrameName, newValue: number) => {
        switch (frame) {
            case "Black":
            case "Honey":
            case "Beige":
            case "Vintage":
            case "Mahogany":
                setQuantity({
                    ...quantity,
                    [frame]: newValue
                })
                return;
            default:
                throw new ExhaustiveCheckError(frame);
        }
    }

    function validateBeforeContinuing(): Option<string> {
        const quantitySet = new Set(Object.entries(quantity));
        let atLeastOneSelected = false;

        for (let [key, value] of quantitySet) {
            if (value < 0 || value > 10) {
                return Option.ofSome(`The quantity of ${key} must be between 0 and 10. It is set to ${value}.`);
            }
            if (value > 0) {
                atLeastOneSelected = true;
            }
        }

        if (!atLeastOneSelected)
            return Option.ofSome("You must select at least one sample frame.");

        if (Stores.Auth.requireCoupaPurchaseOrder && !purchaseOrderId)
            return Option.ofSome("Your location is part of our HMIS+ integration. Please enter a valid Purchase Order ID from HMIS+.");

        if (Stores.Auth.requireCoupaPurchaseOrder && !purchaseOrderIdIsValid(purchaseOrderId))
            return Option.ofSome("You must enter a valid Purchase Order ID.");

        return Option.ofNone();
    }

    const addToCart = () => {
        const validationResult = validateBeforeContinuing();

        if (validationResult.isSome()) {
            setLoadingState(validationResult.get());
            return;
        }

        let customization: Frontend.SampleFrameCustomization;

        if (type === "frames-with-custom-image") {
            // TODO: remove this if block once the type has been removed
            setLoadingState("Sample Frame with Custom Image is not supported right now.");
            return;
        } else {
            customization = { type: type };
        }

        setLoadingState(false);

        Stores.Cart.addSampleFramesToCart({
            uuid: uuid(),
            type: "sample-frame",
            customization: customization,
            qty: quantity,
            source: "quick",
            purchaseOrderId: purchaseOrderId,
            deceasedName: "Sample Frames",
            originalOrderId: null,
        });

        // Skip the cart name page, as the cart store automatically sets the decedent name for sample frames
        setLocation(Stores.Auth.companySettings.siteType === "ecommerce" ? Paths.cart.index : Paths.cart.details);
    };

    return (
        <section id="cart">
            <PageHeader title={`Order Sample Frames`} />
            <div className="section white pure-g center-children">
                <div className="pure-u-22-24">
                    <div className="pure-u-13-24 col-sm-pull-5">
                        <VisualSampleFrameSelector
                            frame={frame}
                            onSelectFrame={setFrame}
                            customization={{type: type === "frames-with-custom-image" ? "frames-with-sample-image" : type}}
                        />
                    </div>
                    <div className="pure-u-2-24" />
                    <div className="pure-u-9-24 col-sm-push-7">
                        <div className="product main" itemScope={true} itemType="http://schema.org/Product">
                            <h1 className="heading" itemProp="name">
                                {"Sample Frame"}
                            </h1>
                            <hr style={{ margin: "25px 0" }} />
                            <Controls
                                error={error}
                                type={type}
                                onSetType={setType}
                                quantity={quantity}
                                onSetQuantity={onSetQuantity}
                                purchaseOrderId={purchaseOrderId}
                                onSetPurchaseOrderId={setPurchaseOrderId}
                                frame={frame}
                                onSetFrame={setFrame}
                                onAddToCart={addToCart}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

type ControlsProps = {
    type: Frontend.SampleFrameCustomization["type"]
    onSetType: (type: Frontend.SampleFrameCustomization["type"]) => void
    purchaseOrderId: string;
    onSetPurchaseOrderId: (id: string) => void;
    frame: Frontend.SampleFrameName
    onSetFrame: (frame: Frontend.SampleFrameName) => void
    quantity: Frontend.SampleFrameQuantities;
    onSetQuantity: (frame: Frontend.SampleFrameName, newValue: number) => void;
    error: string | null
    onAddToCart: () => void;
}

function Controls(props: ControlsProps): JSX.Element {
    const sampleTypes: [Frontend.SampleFrameCustomization["type"], string][] = [
        ["frames-only", "Frames Only"],
        ["corners-only", "Corners Only"],
    ];
    const whitelabel = useWhitelabel();
    const sampleFrameNames = useMemo(() => getSampleFrames(whitelabel.companyName).map(x => x.name), [whitelabel.companyName]);
    const quantityMap = useMemo(() => new Map(Object.entries(props.quantity)), [props.quantity]);

    const onClickButton = (e: React.MouseEvent<any>) => {
        e.preventDefault();
        props.onAddToCart();
    };

    const onSetType = (e: React.FormEvent<HTMLSelectElement>) => {
        props.onSetType(e.currentTarget.value as any);
    }

    return (
        <div key={"all-controls"}>
            <div key={"generic-form-controls"}>
                {Stores.Auth.requireCoupaPurchaseOrder ? (
                    <PurchaseOrderIdBox value={props.purchaseOrderId} onChange={props.onSetPurchaseOrderId} />
                ) : null}
                <div className="selector-container">
                    <p>{"Sample Type"}</p>
                    <select
                        className="form-control selector"
                        value={props.type}
                        onChange={onSetType}
                    >
                        <Repeater source={sampleTypes}>
                            {([optionValue, description]) =>
                                (<option key={optionValue} value={optionValue}>
                                    {description}
                                </option>)
                            }
                        </Repeater>
                    </select>
                </div>
                <Repeater source={sampleFrameNames}>
                    {(frame) =>
                        (<FrameQuantitySelector
                            key={frame}
                            frame={frame}
                            quantity={quantityMap.get(frame) ?? 0}
                            onSetQuantity={props.onSetQuantity}
                         />)
                    }
                </Repeater>
                <div className="selector-container">
                    <MaybeError error={props.error} />
                    <div className="add-to-cart">
                        <button type="button" className="btn blue" onClick={onClickButton}>
                            {"Continue"}
                        </button>
                    </div>
                </div>
            </div>
            <hr className="marBottom25" />
        </div>
    );
}

type FrameQuantitySelectorProps = {
    frame: Frontend.SampleFrameName
    quantity: number
    onSetQuantity: (frame: Frontend.SampleFrameName, value: number) => void
}

export function FrameQuantitySelector(props: FrameQuantitySelectorProps): React.ReactElement {
    const setQuantity = (value: number) => props.onSetQuantity(props.frame, value);

    return (
        <QuantitySelector
            min={0}
            max={10}
            quantity={props.quantity}
            onChange={setQuantity}
            leftLabel={props.frame + " Samples:"}
            rightLabel={Option.ofNone()}
        />
    )
}
