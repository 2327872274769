import * as React from "react";
import * as dates from "shared/dates";

interface Props {
    title: string;
    expiration: Date | null;
    message: string;
    onTitleChange: (newValue: string) => void;
    onExpirationChange: (newValue: Date | null) => void;
    onMessageChange: (newValue: string) => void;
}

export function NewsControls(props: Props): JSX.Element {
    const change =
        (type: "title" | "expiration" | "message") => (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            switch (type) {
                case "expiration":
                    const date = (e.currentTarget as HTMLInputElement).valueAsDate;
                    props.onExpirationChange(date);
                    break;

                case "title":
                    props.onTitleChange(e.currentTarget.value);
                    break;

                case "message":
                    props.onMessageChange(e.currentTarget.value);
                    break;

                default:
                    const neverType: never = type;
                    throw new Error("Unhandled type " + neverType);
            }
        };

    return (
        <React.Fragment>
            <div className="form-group">
                <label>{"Title"}</label>
                <input
                    key="news-title"
                    type="text"
                    className="form-control"
                    onChange={change("title")}
                    value={props.title}
                />
            </div>
            <div className="form-group">
                <label>{"Expiration Date"}</label>
                <input
                    key="news-expiration"
                    type="date"
                    className="form-control"
                    onChange={change("expiration")}
                    value={props.expiration ? dates.toISODateString(props.expiration) : ""}
                />
            </div>
            <div className="form-group">
                <label>{"Message"}</label>
                <textarea
                    key="news-message"
                    rows={10}
                    className="form-control"
                    onChange={change("message")}
                    value={props.message}
                />
            </div>
        </React.Fragment>
    );
}
