import * as React from "react";
import { ReorderDialog as Dialog } from "./reorder-dialog";
import PageHeader from "../../components/page-header";
import Progress from "react-svg-progress/dist";
import { IListResult } from "api";
import { Paths } from "shared/paths";
import { ApiError, Orders } from "client/http";
import { Auth } from "../../stores";
import { Order } from "kmmp";
import { range } from "shared/range";
import { truncate } from "shared/utils";
import { isLineItemVersion1 } from "shared/is-line-item";
import { useUnauthorizedPrompt } from "client/hooks";
import { If } from "shared/components";
import { ExhaustiveCheckError } from "shared/errors";

type Props = React.ClassAttributes<{}>;

interface State {
    orders: Order[];
    loading: boolean;
    page: number;
    total_orders: number;
    reorderingFrom: Order | null;
}

export function HistoryPage(_: Props): JSX.Element {
    const [state, setState] = React.useState<State>({
        orders: [],
        loading: true,
        page: 0,
        total_orders: 0,
        reorderingFrom: null,
    });
    const handleUnauthorized = useUnauthorizedPrompt();
    const limit = 100;

    async function getOrders(page: number, overrideLoadCheck = false) {
        if (state.loading && !overrideLoadCheck) {
            return;
        }

        setState({ ...state, loading: true });

        const api = new Orders(Auth.token.value);
        let result: IListResult<Order>;

        try {
            result = await api.V1.list({
                descending: true,
                limit: limit,
                skip: page * limit,
            });
        } catch (_e: any) {
            const e: ApiError = _e;

            if (e.unauthorized && handleUnauthorized(Paths.history.index)) {
                return;
            }

            setState({ ...state, loading: false });
            alert(e.message);

            return;
        }

        setState({
            ...state,
            orders: result.rows,
            total_orders: result.total_rows,
            page,
            loading: false,
        });
    }

    const openReorderDialog = (order: Order) => {
        setState({ ...state, reorderingFrom: order });
    };

    const closeReorderDialog = () => {
        setState({ ...state, reorderingFrom: null });
    };

    const redirectWithWarning = (e: React.MouseEvent<any>) => {
        const message =
            "Please note: you should only resubmit images if you've been asked to do so by our portrait department staff. Would you like to continue?";

        if (!confirm(message)) {
            e.preventDefault();
        }
    };

    React.useEffect(() => {
        getOrders(0, true);
    }, []);

    let body: JSX.Element;

    if (state.loading) {
        body = (
            <div className="padTop100 padBottom100 text-center">
                <Progress size={100} strokeWidth={3} />
            </div>
        );
    } else {
        const totalPages = Math.ceil((state.total_orders || 0) / limit);
        const pages = range(0, totalPages).map((page) => (
            <option key={"page-" + page} value={page}>{`Page ${page + 1} of ${totalPages}`}</option>
        ));
        const pageSelector = (
            <select
                value={state.page}
                className="form-control float-right"
                style={{ width: "auto" }}
                onChange={(e) => getOrders(parseInt(e.currentTarget.value))}
            >
                {pages}
            </select>
        );

        body = (
            <div>
                <div className="pure-g align-children">
                    <div className="pure-u-1-1 pure-u-md-18-24">
                        <h4 className="page-title strong">
                            {`${state.total_orders} Total. Sorted by date placed, newest to oldest.`}
                        </h4>
                    </div>
                    <div className="pure-u-1-1 pure-u-md-6-24">{pageSelector}</div>
                </div>
                <table className={`full-width bordered`}>
                    <thead>
                        <tr>
                            <th>{`#`}</th>
                            <th>{`Date`}</th>
                            <th>{`Package`}</th>
                            <th>{`Decedent Name`}</th>
                            <th>{`Placed By`}</th>
                            <th>{`Count`}</th>
                            <th colSpan={2}>{`Actions`}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(state.orders || []).map((o) => (
                            <OrderRow
                                order={o}
                                onOpenDialog={openReorderDialog}
                                onClickResubmit={redirectWithWarning}
                                key={o._id || "missing-id"}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div id="history">
            <PageHeader title="Previous Orders" />
            <section className="section white">
                <div className="pure-g center-children">
                    <div className="pure-u-1-1 pure-u-md-20-24">{body}</div>
                </div>
            </section>
            <If condition={state.reorderingFrom !== null}>
                <Dialog originalOrder={state.reorderingFrom!} onClose={closeReorderDialog} />
            </If>
        </div>
    );
}

interface OrderRowProps extends React.Props<any> {
    order: Order;
    onOpenDialog: (order: Order) => void;
    onClickResubmit: (event: React.MouseEvent<any>, order: Order) => void;
}

function OrderRow(this: void, props: OrderRowProps): JSX.Element {
    const o = props.order;
    const lineItem = o.line_items[0];

    if (!lineItem) {
        return (
            <tr key={o._id}>
                <td colSpan={7}>{`Error with order ${o.display_id} (${o._id}): no line items found.`}</td>
            </tr>
        );
    }

    const onOpenDialog = (e: React.MouseEvent<any>) => {
        e.preventDefault();
        props.onOpenDialog(props.order);
    };
    const onClickResubmit = (e: React.MouseEvent<any>) => {
        props.onClickResubmit(e, props.order);
    };
    let showResubmitLink: boolean;
    let packageName: string;

    if (isLineItemVersion1(lineItem)) {
        showResubmitLink = true;
        packageName = lineItem.customization.package;
    } else {
        switch (lineItem.type) {
            case undefined:
            case "portrait":
                showResubmitLink = true;
                packageName = lineItem.customization.package;
                break;

            case "signature-portrait":
                showResubmitLink = true;
                packageName = lineItem.customization.package + " Prestige Portrait";
                break;

            case "shadow-box":
                packageName = lineItem.customization.package + " Prestige Shadow Box";
                showResubmitLink = false;
                break;

            case "signature-jersey":
                packageName = lineItem.customization.package + " Prestige Jersey";
                showResubmitLink = false;
                break;

            case "sample-frame":
                packageName = lineItem.customization.package + " Sample Frames";
                showResubmitLink = false;
                break;

            default:
                throw new ExhaustiveCheckError(lineItem);
        }
    }

    return (
        <tr key={o._id}>
            <td>{`KMMP-${o.display_id}`}</td>
            <td>
                {(o.date_created ? new Date(o.date_created) : new Date()).toLocaleDateString("en-US", {
                    month: "short",
                    year: "numeric",
                    day: "numeric",
                })}
            </td>
            <td>{packageName}</td>
            <td>{truncate(o.line_items[0].deceasedName, 100)}</td>
            <td>{o.details.placedBy}</td>
            <td>{`${o.line_items.length} item${o.line_items.length > 1 ? "s" : ""}`}</td>
            <td>
                {showResubmitLink ? (
                    <a href={`/resub/${o.display_id}`} onClick={onClickResubmit}>
                        {`Resubmit Image`}
                    </a>
                ) : null}
            </td>
            <td>
                <a href={`#`} onClick={onOpenDialog}>
                    {"Reorder Items"}
                </a>
            </td>
        </tr>
    );
}
