import * as React from "react";
import * as dates from "shared/dates";
import classNames = require("classnames");
import { Warning } from "client/components/warning";
import { BannerBackgroundColor } from "kmmp";
import { ExhaustiveCheckError } from "shared/errors";

type Props = {
    maxDescriptionLength: number
    backgroundColor: BannerBackgroundColor
    description: string;
    expirationDate: Date | null;
    message: string;
    startDate: Date | null;

    onBackgroundColorChange: (value: BannerBackgroundColor) => void;
    onDescriptionChange: (value: string) => void;
    onExpirationDateChange: (value: Date | null) => void;
    onMessageChange: (value: string) => void;
    onStartDateChange: (value: Date | null) => void;
}

type ChangeType = "message" | "description" | "startDate" | "expirationDate" | "backgroundColor";

export function BannerControls(props: Props): JSX.Element {
    const change = (type: ChangeType) => (e: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        switch (type) {
            case "message":
                props.onMessageChange(e.currentTarget.value);
                break;
            case "description":
                props.onDescriptionChange(e.currentTarget.value);
                break;
            case "startDate":
                const start = (e.currentTarget as HTMLInputElement).valueAsDate;
                props.onStartDateChange(start);
                break;
            case "expirationDate":
                const exp = (e.currentTarget as HTMLInputElement).valueAsDate;
                props.onExpirationDateChange(exp);
                break;
            case "backgroundColor":
                props.onBackgroundColorChange(e.currentTarget.value as any);
                break;
            default:
                throw new ExhaustiveCheckError(type);
        }
    };

    return (
        <React.Fragment>
            <div className="form-group">
                <label>{"Background Color"}</label>
                <select className="form-control" onChange={change("backgroundColor")} value={props.backgroundColor}>
                    <option value="blue">{"Blue"}</option>
                    <option value="orange">{"Orange"}</option>
                </select>
            </div>
            <div className="form-group">
                <label>{"Description"}</label>
                <input type="text" className="form-control" onChange={change("description")} value={props.description} />
                <span className={classNames("help", {error: props.description.length > props.maxDescriptionLength})}>
                    {`${props.description.length} characters (${props.maxDescriptionLength} max).`}
                </span>
            </div>
            <div className="form-group">
                <label>{"Start date"}</label>
                <input
                    type="date"
                    className="form-control"
                    onChange={change("startDate")}
                    value={props.startDate ? dates.toISODateString(props.startDate) : ""}
                />
                <span className="help">
                    {"The date the banner announcement will be shown to users."}
                </span>
            </div>
            <div className="form-group">
                <label>{"Expiration Date"}</label>
                <input
                    type="date"
                    className="form-control"
                    onChange={change("expirationDate")}
                    value={props.expirationDate ? dates.toISODateString(props.expirationDate) : ""}
                />
                <span className="help">
                    {"The date the banner announcement will stop being shown to users. If no date is given, the banner announcement will be shown indefinitely."}
                </span>
            </div>
            <div className="form-group">
                <label>{"Message"}</label>
                <textarea rows={10} className="form-control" onChange={change("message")} value={props.message} />
            </div>
            <Warning title={"Note"} message="Only one banner announcement can be active at a time. Creating a new banner announcement will deactivate any currently active banner announcements." />
        </React.Fragment>
    );
}
