import * as React from "react";
import { CompanyName } from "kmmp";
import PageHeader from "client/components/page-header";
import { CreateOrModifyUser, UserSummary } from "api";
import { getHumanFriendlyNameForCompany } from "shared/whitelabels";
import { LoadableList } from "client/components/loadable-list";
import { formatDateString } from "shared/dates";
import { ApiError } from "client/http";
import { AdminClient } from "client/http/admin";
import { Auth } from "client/stores/auth";
import { useLocation } from "wouter";
import { useUnauthorizedPrompt } from "client/hooks/use-unauthorized-prompt";
import { EditUserDialog } from "./edit-user-dialog";
import { UserCreatedDialog, ClosedProps as UCDialogClosed, OpenProps as UCDialogOpen } from "./user-created-dialog";
import { useLoadingState } from "client/hooks/use-loading-state";

interface Props {
    companyName: CompanyName;
    page: number;
}

interface EditUserDialogState {
    open: boolean;
    userBeingEdited: UserSummary | null;
}

const LIMIT_PER_PAGE = 250;

export function UserManagementPage(props: Props): JSX.Element {
    const companyName = getHumanFriendlyNameForCompany(props.companyName);
    const client = React.useMemo(() => new AdminClient(Auth.token.value), [Auth.token.value]);
    const handleUnauthorized = useUnauthorizedPrompt();
    const [_, setLocation] = useLocation();
    const [totalPages, setTotalPages] = React.useState(1);
    const [users, setUsers] = React.useState<UserSummary[]>([]);
    const [editDialogState, setEditDialogState] = React.useState<EditUserDialogState>({
        open: false,
        userBeingEdited: null,
    });
    const [ucDialogState, setUCDialogState] = React.useState<UCDialogOpen | UCDialogClosed>({
        open: false,
    });
    const [{ loading, error }, setLoadingState] = useLoadingState(true);

    // Use an effect to load the users for this page
    React.useEffect(() => {
        setLoadingState(true);
        setUsers([]);

        const load = async () => {
            try {
                const result = await client.listUsers({
                    page: props.page,
                    limit: LIMIT_PER_PAGE,
                });

                setUsers(result.users);
                setTotalPages(result.totalPages);
                setLoadingState(false);
            } catch (e: any) {
                console.error("Failed to load users:", e);

                if (e instanceof ApiError) {
                    if (e.unauthorized && handleUnauthorized(window.location.pathname)) {
                        return;
                    }

                    setLoadingState(e.message);
                } else if (e instanceof Error) {
                    setLoadingState(e.message);
                } else {
                    setLoadingState("Something went wrong and the users could not be loaded.");
                }
            }
        };

        load();
    }, [props.page]);

    // Event handlers
    const openEditUserDialog = (user?: UserSummary) => (e?: React.SyntheticEvent) => {
        e?.preventDefault();
        setEditDialogState({
            open: true,
            userBeingEdited: user ?? null,
        });
    };

    const closeEditUserDialog = () =>
        setEditDialogState({
            open: false,
            userBeingEdited: null,
        });

    const closeUCDialog = () =>
        setUCDialogState({
            open: false,
        });

    const navigateToPage = (newPage: number) => {
        setLocation("/admin/users/?page=" + newPage);
    };

    const handleUserUpdated = (userData: CreateOrModifyUser.Response) => {
        const updatedUsers = [...users];
        const existingUserIndex = updatedUsers.findIndex((u) => u._id === userData.id);

        if (existingUserIndex >= 0) {
            // User was updated, replace the object in the list
            updatedUsers.splice(existingUserIndex, 1, userData.user);
        } else {
            // User was created, add it to the top of the list
            updatedUsers.unshift(userData.user);
        }

        closeEditUserDialog();
        setUsers(updatedUsers);

        // If the user was just created, show a dialog displaying their new login information + password
        if (userData.userIsNewUser) {
            setUCDialogState({
                open: true,
                locationId: userData.user.location_id,
                userPassword: userData.userPassword,
            });
        }
    };

    const UserList = () => {
        const baseProps = {
            totalPages,
            page: props.page,
            onSelectPage: navigateToPage,
            columnLabels: ["Location ID", "Date Created", "Location Name"],
        };

        if (loading) {
            return <LoadableList loading={true} {...baseProps} />;
        }

        if (!loading && error) {
            return <LoadableList loading={false} ok={false} message={error} {...baseProps} />;
        }

        return (
            <React.Fragment>
                <div className="text-right">
                    <button id="new-user-button" className="btn blue pull-right" onClick={openEditUserDialog()}>
                        {"Create User"}
                    </button>
                </div>
                <LoadableList loading={false} ok={true} items={users} {...baseProps}>
                    {(user, index) => (
                        <tr key={user._id ?? index}>
                            <td>
                                <a href="#" title="Edit location" onClick={openEditUserDialog(user)}>
                                    {user.location_id}
                                </a>
                            </td>
                            <td>{formatDateString(user.date_created)}</td>
                            <td>{user.location_name}</td>
                        </tr>
                    )}
                </LoadableList>
            </React.Fragment>
        );
    };

    return (
        <div id="user-history" className="admin">
            <PageHeader title={companyName + " user management"} />
            <EditUserDialog
                user={editDialogState.userBeingEdited}
                open={editDialogState.open}
                onClose={closeEditUserDialog}
                onUserUpdated={handleUserUpdated}
                companyName={Auth.session!.company}
            />
            <UserCreatedDialog onClose={closeUCDialog} {...ucDialogState} />
            <section className="section white">
                <div className="pure-g center-children">
                    <div className="pure-u-1-1 pure-u-md-20-24">
                        <UserList />
                    </div>
                </div>
            </section>
        </div>
    );
}
