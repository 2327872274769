import * as React from "react";
import { Announcement } from "kmmp";
import { formatDateString } from "shared/dates";

type Props = {
    announcement: Announcement
    field: "start" | "expiration" | "title" | "type"
}

function maybeFormatDate(date: string | number | Date | false): string {
    if (!date) {
        return "(none)";
    }

    if (typeof date === "string") {
        date = Date.parse(date);
    }

    return formatDateString(date);
}

/**
 * Formats one of the announcement fields as a string. If the "start" or "expiration" timestamp fields are used
 * and the values are false or null, the string "(none)" will be used.
 */
export function AnnouncementField({ field, announcement }: Props): React.ReactElement {
    let value: string;

    switch (field) {
        case "start": {
            switch (announcement.type) {
                case "banner":
                    value = maybeFormatDate(announcement.startTimestamp);
                    break;
                case "breaking":
                    value = maybeFormatDate(announcement.timestamp);
                    break;
                case "holiday":
                    value = maybeFormatDate(announcement.timestamp);
                    break;
            }
            break;
        }
        case "expiration": {
            switch (announcement.type) {
                case "banner":
                    value = maybeFormatDate(announcement.expirationTimestamp);
                    break;
                case "breaking":
                case "holiday":
                    value = maybeFormatDate(announcement.expiration);
                    break;
            }
            break;
        }
        case "title":
            switch (announcement.type) {
                case "banner":
                    value = announcement.description;
                    break;
                case "breaking":
                    value = announcement.title;
                    break;
                case "holiday":
                    value = announcement.name;
                    break;
            }
            break;
        case "type":
            switch (announcement.type) {
                case "banner":
                    value = "Banner Announcement";
                    break;
                case "breaking":
                    value = "News Announcement";
                    break;
                case "holiday":
                    value = "Holiday Announcement";
                    break;
            }
            break;
    }

    return (
        <React.Fragment>
            {value}
        </React.Fragment>
    )
}
