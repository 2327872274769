import * as React from "react";
import * as Frontend from "kmmp/frontend";
import { useMemo } from "react";
import { getSampleFrames, getSampleFramePreviewImageUrl, getSampleFrameImageUrl } from "shared/images";
import { useWhitelabel } from "client/hooks";
import { Repeater } from "shared/components";

type Props = {
    frame?: Frontend.SampleFrameName;
    onSelectFrame: (frame: Frontend.SampleFrameName) => void;
    customization: Frontend.SampleFrameCustomization;
}

/**
 * Component for selecting a sample frame variant by clicking on an image. Also displays the selected sample frame type.
 */
export function VisualSampleFrameSelector(props: Props): JSX.Element {
    const whitelabel = useWhitelabel();
    const sampleFrameImages = useMemo(() => getSampleFrames(whitelabel.companyName), [whitelabel.companyName]);
    const sampleFramePreviewImage = useMemo(() => getSampleFramePreviewImageUrl(props.customization), [props.customization]);

    return (
        <div className="pure-g justify-space-between">
            <div className="pure-u-sm-visible pure-u-sm-9-24">
                <div className="thumbnails">
                    <Repeater source={sampleFrameImages}>
                        {(image) => (
                            <SampleFrameImage key={image.name} image={image} onClick={props.onSelectFrame} />
                        )}
                    </Repeater>
                </div>
            </div>
            <div className="pure-u-sm-visible pure-u-sum-1-24" />
            <div className="pure-u-1 pure-u-sm-14-24">
                <img className="img-responsive"
                    style={{ objectFit: "contain" }}
                    src={sampleFramePreviewImage}
                    alt={"Sample Frames preview"}
                />
            </div>
        </div>
    );
}

type SampleFrameImageProps = {
    image: Frontend.SampleFrameImage
    onClick: (frame: Frontend.SampleFrameName) => void
}

export function SampleFrameImage({image, onClick}: SampleFrameImageProps): React.ReactElement {
    const whitelabel = useWhitelabel();
    const url = useMemo(() => getSampleFrameImageUrl(whitelabel.companyName, image), [whitelabel.companyName, image]);

    const handleClick = (event: React.MouseEvent) => {
        event.preventDefault();
        onClick(image.name);
    }

    return (
       <div className="pure-u-sm-12-24 pure-padded">
            <a href="#" onClick={handleClick}>
                <img style={{ paddingTop: "10px", paddingBottom: "10px" }}
                     src={url}
                     className="img-responsive"
                />
            </a>
        </div>
    );
}
