import * as React from "react";
import * as Frontend from "kmmp/frontend";
import { Dialog } from "@nozzlegear/react-win-dialog";
import { Dev, Auth, Cart } from "client/stores";
import { CompanyName, Order } from "kmmp";
import { LineItemUtils } from "./line-item-utils";
import { LineItem } from "./line-item";
import useLocation from "wouter/use-location";
import { useLoadingState } from "client/hooks/use-loading-state";
import { MaybeError } from "shared/components/maybe-error";
import { purchaseOrderIdIsValid } from "shared/purchase-order-id-is-valid";
import { Paths } from "shared/paths";
import { If } from "shared/components";
import { useWhitelabel } from "client/hooks";
import { getSampleFrames } from "shared/images";

interface Props {
    onClose: () => void;
    originalOrder: Order;
}

interface State {
    cards: number;
    prints: number;
    frameSize: Frontend.FrameSize;
    purchaseOrderId: string;
    specialInstructions: string;
    sampleFrames: Frontend.SampleFrameQuantities;
}

function getDefaultSampleFramesState(companyName: CompanyName): Frontend.SampleFrameQuantities {
    if (companyName !== "sci")
        return {};
    return getSampleFrames("sci").reduce<Frontend.SampleFrameQuantities>((state, frame) => ({
        ...state,
        [frame.name]: 0
    }), {});
}

export function ReorderDialog(props: Props): JSX.Element {
    const whitelabel = useWhitelabel();
    const [state, setState] = React.useState<State>({
        cards: 0,
        prints: 0,
        frameSize: "16x20",
        purchaseOrderId: "",
        specialInstructions: "",
        sampleFrames: getDefaultSampleFramesState(whitelabel.companyName),
    });
    const utils = React.useMemo(() => new LineItemUtils(props.originalOrder.line_items[0]), [props.originalOrder]);
    const [{ loading, error }, setLoadingState] = useLoadingState(false);
    const [_, setLocation] = useLocation();

    // Event handlers
    const setQuantities: (type: "prints" | "cards") => (qty: number) => void = (type) => (qty) =>
        setState({
            ...state,
            prints: type === "prints" ? qty : state.prints,
            cards: type === "cards" ? qty : state.cards,
        });
    const setFrameSize = (frameSize: Frontend.FrameSize) => {
        setState({
            ...state,
            frameSize: frameSize,
        });
    };
    const setPurchaseOrderId: (event: React.FormEvent<HTMLInputElement>) => void = (evt) =>
        setState({
            ...state,
            purchaseOrderId: evt.currentTarget.value,
        });
    const setSpecialInstructions: (event: React.FormEvent<HTMLTextAreaElement>) => void = (evt) =>
        setState({
            ...state,
            specialInstructions: evt.currentTarget.value,
        });

    const addItemsToCart = async (e: React.FormEvent) => {
        e.preventDefault();

        if (loading) return;

        setLoadingState(true);

        const { _id: originalOrderId } = props.originalOrder;

        if (!originalOrderId) {
            return setLoadingState("Unable to determine original order database ID, cannot add reorder to cart.");
        }

        // Check if there are any line items in the cart that aren't from this order.
        if (Dev.allow_cart && Cart.items.some((i) => i.originalOrderId !== originalOrderId)) {
            return setLoadingState(
                `It looks like you've already got some portraits in your cart that aren't originally a part of this order. Orders can only be placed for one decedent at a time. Please finish your order or clear your cart before adding these portraits.`
            );
        }

        if (Auth.requireCoupaPurchaseOrder && !state.purchaseOrderId) {
            return setLoadingState(
                "Your location is part of our HMIS+ integration. Please enter a valid Purchase Order ID from HMIS+."
            );
        }

        if (Auth.requireCoupaPurchaseOrder && !purchaseOrderIdIsValid(state.purchaseOrderId)) {
            return setLoadingState("You must enter a valid Purchase Order ID.");
        }

        const lineItemMapping = utils.tryMapNewLineItem({ ...state });

        if (lineItemMapping.isError()) {
            return setLoadingState(lineItemMapping.getError() as string);
        }

        setLoadingState(false);
        Cart.addReordersToCart([lineItemMapping.getValue()], originalOrderId);
        setLocation(Dev.allow_cart ? Paths.cart.index : Paths.cart.name);
    };

    const dialogTitle = `Reorder portraits from KMMP-${props.originalOrder.display_id}.`;

    return (
        <Dialog
            open={true}
            className={`reorder-dialog`}
            primaryText={Dev.allow_cart ? `Add portraits to cart.` : `Start reorder`}
            secondaryText={`Close`}
            onPrimaryClick={addItemsToCart}
            onSecondaryClick={props.onClose}
            title={dialogTitle}
        >
            <div>
                <If condition={Auth.requireCoupaPurchaseOrder}>
                    <div className={"form-group"}>
                        <label htmlFor={"po-id"} style={{ display: "block" }}>
                            Purchase Order ID
                            <small className="pull-right" style={{ float: "right" }}>
                                Max 7 digits
                            </small>
                        </label>
                        <input
                            type={"text"}
                            maxLength={7}
                            value={state.purchaseOrderId}
                            onChange={setPurchaseOrderId}
                            required={true}
                            name={"po-id"}
                        />
                    </div>
                </If>
                <LineItem
                    item={utils.lineItem}
                    previewImageSrc={utils.previewImageSrc}
                    quantity={state.prints}
                    canChangeFrameSize={utils.supportsChangingFrameSize}
                    frameSize={state.frameSize}
                    onChangeFrameSize={setFrameSize}
                    onChangeQuantity={setQuantities("prints")}
                    isLastItem={false}
                />
                <If condition={utils.supportsPortraitCards}>
                    <LineItem
                        item="CP4"
                        canChangeFrameSize={false}
                        previewImageSrc={utils.previewImageSrc}
                        onChangeQuantity={setQuantities("cards")}
                        quantity={state.cards}
                        isLastItem={true}
                    />
                </If>
                <If condition={utils.supportsSpecialInstructions}>
                    <div className="form-group">
                        <label htmlFor="special-instructions" style={{ display: "block" }}>
                            {"Special Instructions"}
                        </label>
                        <textarea
                            rows={4}
                            name={"special-instructions"}
                            value={state.specialInstructions}
                            onChange={setSpecialInstructions}
                            style={{ resize: "vertical", height: "auto" }}
                        />
                    </div>
                </If>
                <MaybeError error={error} />
            </div>
        </Dialog>
    );
}
