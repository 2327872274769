import { action, observable } from "mobx";
import { BannerAnnouncement, Holiday, NewsAnnouncement } from "kmmp";
import { CONSTANTS } from "client/constants";

type ServerSentEvent = Event & {
    data: string;
};

function isServerSentEventWithData(event: any): event is ServerSentEvent {
    return "data" in event && typeof event.data === "string";
}

class AnnouncementStoreFactory {
    constructor() {
        // Function to connect to the announcement events endpoint and update the observable each time the announcements are changed
        const connect = (reconnectTimeout = 5000) => {
            const eventSource = new EventSource("/api/v1/announcements/stream", {
                withCredentials: true,
            });

            eventSource.onopen = () => {
                console.info("Connected to announcements stream");
                // Reset the connection timeout
                reconnectTimeout = 5000;
            };

            eventSource.addEventListener(CONSTANTS.ANNOUNCEMENTS_CHANGED_EVENT, (event) => {
                if (isServerSentEventWithData(event)) {
                    this.updateAnnouncements(JSON.parse(event.data));
                }
            });

            eventSource.addEventListener(CONSTANTS.BANNER_ANNOUNCEMENT_CHANGED_EVENT, event => {
                if (isServerSentEventWithData(event)) {
                    this.updateBanner(JSON.parse(event.data));
                }
            });

            eventSource.onerror = (errEvent) => {
                console.error(
                    `Encountered error with announcement events stream, attempting to reconnect in ${
                        reconnectTimeout / 1000
                    } seconds`,
                    errEvent
                );

                // Close the current event source, then try to reconnect after the timeout
                eventSource.close();

                setTimeout(() => {
                    // Set the maximum timeout to five minutes
                    const fiveMins = 60000 * 5;
                    const newTimeout = reconnectTimeout >= fiveMins ? fiveMins : reconnectTimeout + 5000;

                    connect(newTimeout);
                }, reconnectTimeout);
            };
        };

        connect();
    }

    announcements = observable.box<ReadonlyArray<NewsAnnouncement | Holiday>>([]);

    banner = observable.box<BannerAnnouncement | null>(null);

    @action updateAnnouncements = (announcements: ReadonlyArray<NewsAnnouncement | Holiday>) => {
        this.announcements.set(announcements);
    };

    @action updateBanner = (banner: BannerAnnouncement | null) => {
        this.banner.set(banner);
    }
}

export const Announcements = new AnnouncementStoreFactory();

// Make the store available to the window for test purposes
window["AnnouncementStore"] = Announcements;
