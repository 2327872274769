import * as React from "react";
import classNames = require("classnames");
import { useObserver } from "mobx-react";
import { Announcements } from "client/stores/announcements";
import { Repeater } from "shared/components";

type Props = {}

export function BannerAnnouncement(_: Props): React.ReactElement {
    const banner = useObserver(() => Announcements.banner.get());

    if (!banner)
        return <React.Fragment></React.Fragment>

    const lines = typeof banner.message === "string" ? [banner.message] : banner.message;
    const colorClasses = {
        orange: banner.backgroundColor === "orange",
        blue: banner.backgroundColor === "blue",
    }

    return (
        <div className={classNames("banner-announcement-root accent", colorClasses)}>
            <div className="banner-announcement-content">
                <Repeater source={lines}>
                    {(line, index) => <p key={index}>{line}</p>}
                </Repeater>
            </div>
        </div>
    )
}
