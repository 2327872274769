import * as React from "react";
import { AuthPage } from "./pages/auth";
import { CartDetailsPage as CartDetails } from "./pages/cart/details";
import { CartNamePage as CartName } from "./pages/cart/name";
import { CartShippingPage as CartShipping } from "./pages/cart/shipping";
import { CartSummaryPage as CartSummary } from "./pages/cart/summary";
import { CheckoutPage as CheckoutPage } from "./pages/checkout";
import { ConfirmedPanel as Confirmed } from "./pages/confirmation";
import ErrorPage from "./pages/error/error";
import Guide from "./pages/home/guide";
import { HistoryPage as History } from "./pages/history/history";
import Home from "./pages/home/home";
import Paths from "shared/paths";
import { ResubmissionPage as ResubPage } from "./pages/resub";
import { QuickPage as Quick } from "./pages/quick";
import { RequireFilledCart } from "./components/cart";
import { Logout, RequirePilotProductLocation } from "./components/auth";
import { ShadowBoxesPage } from "./pages/quick/shadow_boxes";
import { SignatureJerseysPage } from "./pages/quick/signature_jerseys";
import { Auth as AuthStore } from "./stores";
import { Provider } from "mobx-react";
import { render as renderComponent } from "react-dom";
import ga = require("ga");
import { compute } from "@nozzlegear/railway";
import { ProductListPage } from "./pages/products/list";
import { Redirect, Switch } from "wouter";
import { wrapRoute } from "client/components/wrappable-route";
import { MainLayout, MinimalLayout } from "client/components/layout";
import { useWhitelabel } from "./hooks";
import { OrderHistory } from "./pages/admin/order-history";
import { UserManagementPage } from "./pages/admin/user-management";
import { AnnouncementsPage } from "./pages/admin/announcements";
import { SampleFramesPage } from "./pages/quick/sample_frames";

function Client(props: React.ClassAttributes<{}>): JSX.Element {
    const whitelabelSettings = useWhitelabel();

    // Track whether the user is logged in
    if (!AuthStore.sessionIsInvalid && AuthStore.session) {
        ga("send", "userId", AuthStore.session.location_id);
    }

    // Routes that wrap the component in either a MainLayout or MinimalLayout
    const MainRoute = wrapRoute((children) => {
        return <MainLayout whitelabelSettings={whitelabelSettings}>{children}</MainLayout>;
    });
    const MinimalRoute = wrapRoute((children) => {
        return <MinimalLayout whitelabelSettings={whitelabelSettings}>{children}</MinimalLayout>;
    });

    const parsePageFromQuerystring = () => {
        const qs = new URLSearchParams(window.location.search);

        return qs.has("page") ? parseInt(qs.get("page")!) : 1;
    };

    const adminRoutes = (
        <>
            <MainRoute path={Paths.admin.orderHistory} requireAdmin>
                {() => <OrderHistory companyName={AuthStore.session!.company} page={parsePageFromQuerystring()} />}
            </MainRoute>
            <MainRoute path={Paths.admin.userManagement} requireAdmin>
                {() => (
                    <UserManagementPage companyName={AuthStore.session!.company} page={parsePageFromQuerystring()} />
                )}
            </MainRoute>
            <MainRoute path={Paths.admin.announcements} requireAdmin>
                <AnnouncementsPage page={parsePageFromQuerystring()} />
            </MainRoute>
        </>
    );

    const mainRoutes = compute<JSX.Element>(() => {
        if (whitelabelSettings.siteType === "corporate") {
            return (
                <>
                    <MainRoute path={Paths.home.index}>
                        <Home />
                    </MainRoute>
                    <MainRoute path={Paths.home.guide}>
                        <Guide />
                    </MainRoute>
                    <MainRoute path={Paths.quick.index} anonymous>
                        <Quick />
                    </MainRoute>
                    <MainRoute path={Paths.resub.index}>
                        <Redirect to={Paths.home.index} replace />
                    </MainRoute>
                    <MainRoute path={Paths.resub.index + "/:id"}>{(params) => <ResubPage id={params.id} />}</MainRoute>
                    <MainRoute path={Paths.cart.name}>
                        <CartName />
                    </MainRoute>
                    <MainRoute path={Paths.cart.shipping}>
                        <CartShipping />
                    </MainRoute>
                    <MainRoute path={Paths.cart.details}>
                        <CartDetails />
                    </MainRoute>
                    <MainRoute path={Paths.cart.index}>
                        <CartSummary />
                    </MainRoute>
                    <MainRoute path={Paths.confirmed.index}>
                        <Confirmed />
                    </MainRoute>
                    <MainRoute path={Paths.history.index}>
                        <History />
                    </MainRoute>
                    <MainRoute path={Paths.quick.signaturePortraits}>
                        <RequirePilotProductLocation>
                            <Quick signaturePortraitsOnly={true} />
                        </RequirePilotProductLocation>
                    </MainRoute>
                    <MainRoute path={Paths.quick.shadowBoxes}>
                        <RequirePilotProductLocation>
                            <ShadowBoxesPage />
                        </RequirePilotProductLocation>
                    </MainRoute>
                    <MainRoute path={Paths.quick.signatureJerseys}>
                        <RequirePilotProductLocation>
                            <SignatureJerseysPage />
                        </RequirePilotProductLocation>
                    </MainRoute>
                    <MainRoute path={Paths.quick.sampleFrames}>
                        <SampleFramesPage />
                    </MainRoute>
                </>
            );
        }

        // Return ecommerce routes
        return (
            <>
                <MainRoute path={Paths.checkout.index} anonymous>
                    <RequireFilledCart>
                        <CheckoutPage />
                    </RequireFilledCart>
                </MainRoute>
                <MainRoute path={"/order/:id"} anonymous>
                    <h1>{"Track your order here"}</h1>
                </MainRoute>
                <MainRoute path={Paths.home.index} anonymous>
                    <Home />
                </MainRoute>
                <MainRoute path={Paths.products.index} anonymous>
                    <ProductListPage />
                </MainRoute>
                <MainRoute path={Paths.products.product.splat} anonymous>
                    {(props) => <Quick {...props} />}
                </MainRoute>
                <MainRoute path={Paths.cart.index} anonymous>
                    <CartSummary />
                </MainRoute>
            </>
        );
    });

    return (
        <Provider>
            <Switch>
                <MinimalRoute path={Paths.auth.logout} anonymous>
                    <Logout />
                </MinimalRoute>

                <MinimalRoute path={"/account/login"} anonymous>
                    <Redirect to={Paths.auth.login} replace />
                </MinimalRoute>

                <MinimalRoute path={Paths.auth.login} anonymous>
                    <AuthPage />
                </MinimalRoute>

                {mainRoutes}

                {adminRoutes}

                <MinimalRoute path={"/error/:statusCode"} anonymous>
                    {(p) => <ErrorPage statusCode={p.statusCode} />}
                </MinimalRoute>

                <MinimalRoute anonymous>
                    <Redirect to={"/error/404"} />
                </MinimalRoute>
            </Switch>
        </Provider>
    );
}

(() => {
    const el = document.getElementById("contenthost")!;

    try {
        renderComponent(<Client />, el);
    } catch (e: any) {
        const message = e instanceof Error ? e.message : JSON.stringify(e);
        console.error("Failed to render component in #contenthost: " + message, e);

        el.innerText = "Failed to render component in #contenthost: " + message;
    }
})();
