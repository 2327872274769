import * as React from "react";
import * as Frontend from "kmmp/frontend";
import { Dialog } from "@nozzlegear/react-win-dialog";
import { Paths } from "shared/paths";
import { ImageEditor } from "client/components/image-editor";
import { useImageUploader, useUnauthorizedPrompt, useState } from "client/hooks";
import { Option } from "@nozzlegear/railway";
import { Maybe } from "@nozzlegear/railway-react";
import { ApiError } from "client/http";
import { Sentry } from "client/modules/sentry";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onImagesUploaded: (images: Frontend.UploadedImage[]) => void;
    orientation: Frontend.Orientation;
}

interface State {
    hideControls: boolean;
    error: Option<string>;
}

export function ImageUploadDialog(props: Props): JSX.Element {
    const handleUnauthorized = useUnauthorizedPrompt();
    const [isUploadingImages, allImages, imageControls] = useImageUploader();
    const [state, setState] = useState<State>({
        hideControls: false,
        error: Option.ofNone(),
    });

    const hideControls = () =>
        setState({
            hideControls: true,
        });

    const showControls = () =>
        setState({
            hideControls: false,
        });

    const upload = async () => {
        if (isUploadingImages) {
            return;
        }

        if (allImages.length === 0) {
            setState({ error: Option.ofSome("You must select an image first.") });

            return;
        }

        setState({ error: Option.ofNone<string>() });

        let uploadedImages: Frontend.UploadedImage[];

        try {
            // Upload the images. The image hook will handle setting the isUploading state.
            uploadedImages = await imageControls.uploadImages();
        } catch (_e: any) {
            const e: ApiError = _e;
            const sourceId = "4eb13893-49b0-4f42-8199-da5b2165315f";

            if (e.unauthorized && handleUnauthorized(Paths.quick.index)) {
                return;
            }

            console.error(e);

            Sentry.captureException(e, {
                extra: {
                    sourceId: sourceId,
                },
            });

            setState({ error: Option.ofSome(`${e.message} (id: ${sourceId})`) });

            // Do not continue further if an error occurred
            return;
        }

        props.onImagesUploaded(uploadedImages);
        imageControls.reset();
    };

    const shouldHideControls = state.hideControls || isUploadingImages;

    return (
        <Dialog
            title={`Upload Portrait Image`}
            onPrimaryClick={upload}
            onSecondaryClick={props.onClose}
            open={props.isOpen}
            primaryText={shouldHideControls ? undefined : `Upload & Continue`}
            secondaryText={shouldHideControls ? undefined : `Close`}
            dialogStyle={{ maxWidth: "80vw" }}
        >
            <ImageEditor
                allImages={[...allImages]}
                controls={imageControls}
                isUploading={isUploadingImages}
                onRequestHideInteractions={hideControls}
                onRequestShowInteractions={showControls}
                orientation={props.orientation}
            />
            <Maybe value={state.error}>{(str) => <p className="error red text-center">{str}</p>}</Maybe>
        </Dialog>
    );
}
