import * as React from "react";
import PageHeader from "client/components/page-header";
import { CompanyName } from "kmmp";
import { ListAdminOrders } from "api";
import { getHumanFriendlyNameForCompany } from "shared/whitelabels";
import { useLocation } from "wouter";
import { AdminClient } from "client/http/admin";
import { Auth } from "client/stores";
import { ApiError } from "client/http";
import { useUnauthorizedPrompt } from "client/hooks";
import { formatDateString } from "shared/dates";
import { LoadableList } from "client/components/loadable-list";

interface Props {
    page: number;
    companyName: CompanyName;
}

const LIMIT_PER_PAGE = 250;

export function OrderHistory(props: Props): JSX.Element {
    const companyName = getHumanFriendlyNameForCompany(props.companyName);
    const client = React.useMemo(() => new AdminClient(Auth.token.value), [Auth.token.value]);
    const handleUnauthorized = useUnauthorizedPrompt();
    const [_, setLocation] = useLocation();
    const [totalPages, setTotalPages] = React.useState(1);
    const [orders, setOrders] = React.useState<ListAdminOrders.OrderSummary[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<string | null>(null);

    // Use an effect to load the orders for this page
    React.useEffect(() => {
        setLoading(true);
        setError(null);
        setOrders([]);

        const load = async () => {
            try {
                const result = await client.listOrders({
                    page: props.page,
                    limit: LIMIT_PER_PAGE,
                });

                setOrders(result.orders);
                setTotalPages(result.totalPages);
            } catch (e: any) {
                console.error("Failed to load orders:", e);

                if (e instanceof ApiError) {
                    if (e.unauthorized && handleUnauthorized(window.location.pathname)) {
                        return;
                    }

                    setError(e.message);
                } else if (e instanceof Error) {
                    setError(e.message);
                } else {
                    setError("Something went wrong and the orders could not be loaded.");
                }
            } finally {
                setLoading(false);
            }
        };

        load();
    }, [props.page]);

    const navigateToPage = (newPage: number) => {
        setLocation("/admin/history/?page=" + newPage);
    };

    const OrderList = () => {
        const baseProps = {
            totalPages,
            page: props.page,
            onSelectPage: navigateToPage,
            columnLabels: ["Order ID", "Date Placed", "Location ID", "Location Name", "Placed By"],
        };

        if (loading) {
            return <LoadableList loading={true} {...baseProps} />;
        }

        if (!loading && error) {
            return <LoadableList loading={false} ok={false} message={error} {...baseProps} />;
        }

        return (
            <LoadableList loading={false} ok={true} items={orders} {...baseProps}>
                {(order, index) => (
                    <tr key={order._id ?? index}>
                        <td>{order.display_id || ""}</td>
                        <td>{formatDateString(order.date_created)}</td>
                        <td>{order.location_id}</td>
                        <td>{order.location_name}</td>
                        <td>{order.placed_by}</td>
                    </tr>
                )}
            </LoadableList>
        );
    };

    return (
        <div id="order-history" className="admin">
            <PageHeader title={"Order history for " + companyName + " locations"} />
            <section className="section white">
                <div className="pure-g center-children">
                    <div className="pure-u-1-1 pure-u-md-20-24">
                        <OrderList />
                    </div>
                </div>
            </section>
        </div>
    );
}
