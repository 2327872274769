import * as V1 from "kmmp/orders/v1";
import * as V2 from "kmmp/orders/v2";
import * as Frontend from "kmmp/frontend";
import { mapStringToFrame } from "shared/map-string-to-frame";
import { mapToFrontendImage } from "shared/map_images";
import { isPortraitCustomizationVersion1 } from "shared/is-customization";
import { isLineItemVersion1 } from "shared/is-line-item";
import { CreateOrderRequest } from "api";
import { ExhaustiveCheckError } from "./errors";

export function mapToPortraitLineItem(item: V1.LineItem | V2.PortraitLineItem): Frontend.PortraitLineItem {
    const images = [...item.images];

    return {
        type: "portrait",
        customization: isPortraitCustomizationVersion1(item.customization)
            ? {
                  background: {
                      name: item.customization.background.name,
                      fileName: item.customization.background.image,
                      notes: item.customization.background.notes,
                  },
                  frame: {
                      name: mapStringToFrame(item.customization.frame.name),
                      fileName: item.customization.frame.portraitImage,
                  },
                  orientation: item.customization.orientation,
                  package: item.customization.package,
              }
            : {
                  background: {
                      fileName: item.customization.background.fileName,
                      name: item.customization.background.name,
                      notes: "",
                  },
                  frame: {
                      name: item.customization.frame.name,
                      fileName: item.customization.frame.fileName,
                  },
                  orientation: item.customization.orientation,
                  package: item.customization.package,
              },
        deceasedName: item.deceasedName,
        images: images.map<Frontend.UploadedImage>(mapToFrontendImage),
        originalOrderId: item.originalOrderId || null,
        purchaseOrderId: item.purchaseOrderId || null,
        source: item.source,
        uuid: item.uuid,
        qty: {
            prints: item.quantities.extraPrints,
            cards: item.quantities.extraCards,
            packages: item.quantities.total,
        },
    };
}

export function mapToShadowBoxLineItem(item: V2.ShadowBoxLineItem): Frontend.ShadowBoxLineItem {
    const images = [...item.images];

    return {
        type: "shadow-box",
        customization: {
            package: item.customization.package,
            color: item.customization.color,
            engravingName: item.customization.engravingName,
            engravingDate1: item.customization.engravingDate1,
            engravingDate2: item.customization.engravingDate2,
            engravingSentiment: item.customization.engravingSentiment,
        },
        qty: {
            packages: item.quantities.total,
        },
        deceasedName: item.deceasedName,
        images: images.map<Frontend.UploadedImage>(mapToFrontendImage),
        originalOrderId: item.originalOrderId || null,
        purchaseOrderId: item.purchaseOrderId || null,
        source: item.source,
        uuid: item.uuid,
    };
}

export function mapToSignatureJerseyLineItem(item: V2.SignatureJerseyLineItem): Frontend.SignatureJerseyLineItem {
    return {
        type: "signature-jersey",
        customization: {
            package: item.customization.package,
            frame: item.customization.frame,
            engravingName: item.customization.engravingName,
            engravingDate1: item.customization.engravingDate1,
            engravingDate2: item.customization.engravingDate2,
            engravingSentiment: item.customization.engravingSentiment,
        },
        qty: {
            packages: item.quantities.total,
        },
        deceasedName: item.deceasedName,
        originalOrderId: item.originalOrderId || null,
        purchaseOrderId: item.purchaseOrderId || null,
        source: item.source,
        uuid: item.uuid,
    };
}

export function mapToSignaturePortraitLineItem(item: V2.SignaturePortraitLineItem): Frontend.SignaturePortraitLineItem {
    const images = [...item.images];

    return {
        type: "signature-portrait",
        customization: {
            package: item.customization.package,
            frame: item.customization.frame,
            orientation: item.customization.orientation,
            background: item.customization.background,
            engravingName: item.customization.engravingName,
            engravingDate1: item.customization.engravingDate1,
            engravingDate2: item.customization.engravingDate2,
            engravingSentiment: item.customization.engravingSentiment,
        },
        qty: {
            packages: item.quantities.total,
        },
        images: images.map<Frontend.UploadedImage>(mapToFrontendImage),
        deceasedName: item.deceasedName,
        originalOrderId: item.originalOrderId || null,
        purchaseOrderId: item.purchaseOrderId || null,
        source: item.source,
        uuid: item.uuid,
    };
}

function mapToSampleFrameLineItem(item: V2.SampleFrameLineItem): Frontend.LineItem {
    let customization: Frontend.SampleFrameCustomization;

    switch (item.customization.type) {
        case "frames-only":
        case "frames-with-sample-image":
        case "corners-only":
            customization = item.customization;
            break;
        case "frames-with-custom-image":
            customization = {
                type: item.customization.type,
                image: mapToFrontendImage(item.customization.image),
            }
            break;
        default:
            throw new ExhaustiveCheckError(item.customization);
    }

    return {
        type: "sample-frame",
        uuid: item.uuid,
        qty: item.quantities,
        source: item.source,
        deceasedName: item.deceasedName,
        customization: customization,
        originalOrderId: item.originalOrderId,
        purchaseOrderId: item.purchaseOrderId
    }
}

/**
 * Maps a database-style line item to a frontend line item.
 */
export function mapToFrontendLineItem(item: V1.LineItem | V2.LineItem): Frontend.LineItem {
    // Version 1 line items were always portraits
    if (isLineItemVersion1(item)) {
        return mapToPortraitLineItem(item);
    }

    // Portrait line items may not have a type at all, which by default means they are portrait line items as the other line item types always have a type property.
    switch (item.type) {
        case undefined:
        case "portrait":
            return mapToPortraitLineItem(item);

        case "shadow-box":
            return mapToShadowBoxLineItem(item);

        case "signature-jersey":
            return mapToSignatureJerseyLineItem(item);

        case "signature-portrait":
            return mapToSignaturePortraitLineItem(item);

        case "sample-frame":
            return mapToSampleFrameLineItem(item);

        default:
            throw new ExhaustiveCheckError(item);
    }
}

export function mapToCreateOrderReqImage(i: Frontend.UploadedImage): CreateOrderRequest.Image {
    return {
        uuid: i.uuid,
        special_instructions: i.instructions,
        too_small: i.tooSmall,
        cropped_image: i.croppedImage
            ? {
                  file_name: i.croppedImage.fileName,
                  full_azure_url: i.croppedImage.srcUrl,
                  thumbnail_azure_url: i.croppedImage.thumbnailUrl,
              }
            : null,
        full_image: {
            file_name: i.fullImage.fileName,
            full_azure_url: i.fullImage.srcUrl,
            thumbnail_azure_url: i.fullImage.thumbnailUrl,
        },
    };
}

export function mapToCreateOrderReqLineItem(item: Frontend.LineItem): CreateOrderRequest.LineItem {
    const baseLineItem: CreateOrderRequest.BaseLineItem = {
        original_order_id: item.originalOrderId || null,
        purchase_order_id: item.purchaseOrderId || null,
        source: item.source,
        special_instructions: "",
        subject_name: item.deceasedName,
        uuid: item.uuid,
    };

    // Portrait line items may not have a type at all, which by default means they are portrait line items as the other line item types always have a type property.
    switch (item.type) {
        case undefined:
        case "portrait":
            return {
                ...baseLineItem,
                type: "portrait",
                customization: {
                    background: {
                        fileName: item.customization.background.fileName,
                        name: item.customization.background.name,
                    },
                    frame: {
                        fileName: item.customization.frame.fileName,
                        name: item.customization.frame.name,
                    },
                    orientation: item.customization.orientation,
                    package: item.customization.package,
                },
                images: item.images.map((i) => mapToCreateOrderReqImage(i)),
                quantities: {
                    extra_cards: item.qty.cards,
                    extra_prints: item.qty.prints,
                    total: item.qty.packages,
                },
                resubmitted: false,
            };

        case "shadow-box":
            return {
                ...baseLineItem,
                type: "shadow-box",
                customization: {
                    package: item.customization.package,
                    color: item.customization.color,
                    engravingName: item.customization.engravingName,
                    engravingDate1: item.customization.engravingDate1,
                    engravingDate2: item.customization.engravingDate2,
                    engravingSentiment: item.customization.engravingSentiment,
                },
                images: item.images.map((i) => mapToCreateOrderReqImage(i)),
                quantities: {
                    total: item.qty.packages,
                },
                resubmitted: false,
            };

        case "signature-jersey":
            return {
                ...baseLineItem,
                type: "signature-jersey",
                customization: {
                    package: item.customization.package,
                    frame: item.customization.frame,
                    engravingName: item.customization.engravingName,
                    engravingDate1: item.customization.engravingDate1,
                    engravingDate2: item.customization.engravingDate2,
                    engravingSentiment: item.customization.engravingSentiment,
                },
                quantities: {
                    total: item.qty.packages,
                },
            };

        case "signature-portrait":
            return {
                ...baseLineItem,
                type: "signature-portrait",
                images: item.images.map((i) => mapToCreateOrderReqImage(i)),
                customization: {
                    package: item.customization.package,
                    frame: item.customization.frame,
                    background: item.customization.background,
                    orientation: item.customization.orientation,
                    engravingName: item.customization.engravingName,
                    engravingDate1: item.customization.engravingDate1,
                    engravingDate2: item.customization.engravingDate2,
                    engravingSentiment: item.customization.engravingSentiment,
                },
                quantities: {
                    total: item.qty.packages,
                },
                resubmitted: false,
            };

        case "sample-frame": {
            let customization: CreateOrderRequest.SampleFrameCustomization;

            switch (item.customization.type) {
                case "frames-with-custom-image":
                    customization = {
                        package: "SF1",
                        type: "frames-with-custom-image",
                        image: mapToCreateOrderReqImage(item.customization.image),
                    }
                    break;
                case "frames-only":
                case "corners-only":
                case "frames-with-sample-image":
                    customization = { type: item.customization.type, package: "SF1" }
                    break;
                default:
                    throw new ExhaustiveCheckError(item.customization);
            }

            return {
                ...baseLineItem,
                type: "sample-frame",
                quantities: item.qty,
                customization: customization,
            }
        }

        default:
            throw new ExhaustiveCheckError(item);
    }
}
