import * as React from "react";
import * as dates from "shared/dates";
import * as utils from "shared/utils";
import { Announcement, BannerBackgroundColor } from "kmmp";
import { AnnouncementsClient } from "client/http/announcements";
import { CreateOrModifyAnnouncement } from "api";
import { Dialog } from "@nozzlegear/react-win-dialog";
import { useLoadingState } from "client/hooks/use-loading-state";
import { useUnauthorizedPrompt } from "client/hooks/use-unauthorized-prompt";
import { MaybeError } from "shared/components/maybe-error";
import { HolidayControls } from "./holiday-controls";
import { NewsControls } from "./news-controls";
import { If } from "shared/components";
import { Auth } from "client/stores";
import { Result } from "@nozzlegear/railway";
import { ApiError } from "client/http";
import { BannerControls } from "./banner-controls";

type AnnouncementType = Announcement["type"];

interface Props {
    open: boolean;
    announcement: Announcement | null;
    onAnnouncementSaved: (data: CreateOrModifyAnnouncement.Response) => void;
    onClose: () => void;
}

interface State {
    type: AnnouncementType;
    title: string;
    description: string;
    startTimestamp: Date | null;
    displayDate: string | null;
    expirationTimestamp: Date | null;
    partingPhrase: string;
    message: string;
    backgroundColor: BannerBackgroundColor;
}

const MAX_BANNER_DESCRIPTION_LENGTH = 128;

function getDefaultState(announcement: Announcement | null): State {
    switch (announcement?.type) {
        case undefined:
        case "breaking": {
            const message = announcement?.message ?? "";
            return {
                backgroundColor: "blue",
                description: "",
                displayDate: null,
                expirationTimestamp: dates.toDateOrNull(announcement?.expiration),
                message: Array.isArray(message) ? message.join("\n\n") : message,
                partingPhrase: "",
                startTimestamp: dates.toDateOrNull(announcement?.timestamp),
                title: announcement?.title ?? "",
                type: "breaking",
            };
        }

        case "holiday": {
            const message = announcement.customMessage ?? "";
            return {
                backgroundColor: "blue",
                description: "",
                displayDate: announcement.displayDate ?? null,
                expirationTimestamp: dates.toDateOrNull(announcement?.expiration),
                message: Array.isArray(message) ? message.join("\n\n") : message,
                partingPhrase: announcement.salutation ?? "",
                startTimestamp: dates.toDateOrNull(announcement.timestamp),
                title: announcement?.name ?? "",
                type: "holiday",
            }
        }

        case "banner": {
            const message = announcement.message ?? "";
            return {
                backgroundColor: announcement.backgroundColor,
                description: announcement.description,
                displayDate: null,
                expirationTimestamp: dates.toDateOrNull(announcement.expirationTimestamp),
                message: Array.isArray(message) ? message.join("\n\n") : message,
                partingPhrase: "",
                startTimestamp: dates.toDateOrNull(announcement.startTimestamp),
                title: "",
                type: "banner",
            }
        }
    }
}

function mapStateToRequest(state: State): Result<CreateOrModifyAnnouncement.Request> {
    const start = state.startTimestamp !== null ? dates.toTimestamp(state.startTimestamp) : Date.now();
    const expiration = state.expirationTimestamp !== null ? dates.toTimestamp(state.expirationTimestamp) : false;

    switch (state.type) {
        case "breaking": {
            return Result.ofValue({
                expiration: expiration,
                message: utils.splitLines(state.message),
                timestamp: start,
                title: state.title,
                type: "breaking",
            });
        }
        case "holiday": {
            if (state.startTimestamp === null) {
                return Result.ofError("Start Date cannot be empty.");
            }

            return Result.ofValue({
                customMessage: state.message,
                displayDate: state.displayDate ?? undefined,
                expiration: expiration,
                name: state.title,
                salutation: state.partingPhrase,
                timestamp: dates.toTimestamp(state.startTimestamp),
                type: "holiday",
            })
        }
        case "banner": {
            if (!state.message)
                return Result.ofError("Message cannot be empty.");

            if (!state.description)
                return Result.ofError("Description cannot be empty.");

            if (state.description.length > MAX_BANNER_DESCRIPTION_LENGTH)
                return Result.ofError("Description cannot be more than 128 characters.");

            return Result.ofValue({
                backgroundColor: state.backgroundColor,
                createdTimestamp: Date.now(),
                description: state.description,
                expirationTimestamp: expiration,
                message: state.message,
                startTimestamp: start,
                type: "banner",
            })
        }
    }
}

export function EditDialog(props: Props): JSX.Element {
    const handleUnauthorized = useUnauthorizedPrompt();
    const client = React.useMemo(() => new AnnouncementsClient(Auth.token.value), []);
    const [state, setState] = React.useState(() => getDefaultState(props.announcement));
    const [{ loading, error }, setLoadingState] = useLoadingState(false);

    async function saveChanges() {
        if (loading) return;

        const validation = mapStateToRequest(state);

        if (validation.isError()) {
            const error = validation.getError() as string;

            return setLoadingState(error);
        }

        setLoadingState(true);

        try {
            const request = validation.getValue();
            const result = props.announcement
                ? await client.modifyAnnouncement(props.announcement._id!, props.announcement._rev!, request)
                : await client.createAnnouncement(request);

            setLoadingState(false);
            props.onAnnouncementSaved(result);
        } catch (e: any) {
            console.error("Failed to save announcement changes:", e);

            if (e instanceof ApiError && e.unauthorized) {
                handleUnauthorized();
            }

            setLoadingState(
                e instanceof Error ? e.message : "Something went wrong and the announcement could not be saved."
            );
        }
    }

    function setType(e: React.FormEvent<HTMLSelectElement>) {
        let newType: AnnouncementType;
        switch (e.currentTarget.value as AnnouncementType) {
            case "holiday":
                newType = "holiday";
                break;
            case "breaking":
                newType = "breaking";
                break;
            case "banner":
                newType = "banner";
                break;
        }

        setState({
            ...state,
            type: newType,
        });
    }

    function setExpiration(newValue: Date | null) {
        setState({
            ...state,
            expirationTimestamp: newValue,
        });
    }

    function setMessage(newValue: string) {
        setState({
            ...state,
            message: newValue,
        });
    }

    function setDescription(newValue: string) {
        setState({
            ...state,
            description: newValue,
        });
    }

    function setTitle(newValue: string) {
        setState({
            ...state,
            title: newValue,
        });
    }

    function onStartChange(newValue: Date | null) {
        setState({
            ...state,
            startTimestamp: newValue,
        });
    }

    function onDisplayDateChange(newValue: string | null) {
        setState({
            ...state,
            displayDate: newValue,
        });
    }

    function onPartingPhraseChange(newValue: string) {
        setState({
            ...state,
            partingPhrase: newValue,
        });
    }

    function onBackgroundColorChange(newValue: BannerBackgroundColor) {
        setState({
            ...state,
            backgroundColor: newValue
        });
    }

    return (
        <Dialog
            open={props.open}
            title={props.announcement !== null ? "Edit Announcement" : "Create Announcement"}
            primaryText="Save Announcement"
            secondaryText="Close"
            loadingHidesButtons={true}
            onPrimaryClick={saveChanges}
            onSecondaryClick={props.onClose}
            loading={loading}
            className="edit-announcement-dialog"
            overlayPreventsScrolling={false}
        >
            <form onSubmit={saveChanges}>
                <div className="form-group">
                    <label>{"Announcement Type"}</label>
                    <select className="form-control" value={state.type} onChange={setType}>
                        <option value={"breaking"}>{"News Announcement"}</option>
                        <option value={"holiday"}>{"Holiday Announcement"}</option>
                        <option value={"banner"}>{"Banner Announcement"}</option>
                    </select>
                </div>
                <If condition={state.type === "breaking"}>
                    <NewsControls
                        key="news-controls"
                        expiration={state.expirationTimestamp}
                        message={state.message}
                        title={state.title}
                        onExpirationChange={setExpiration}
                        onMessageChange={setMessage}
                        onTitleChange={setTitle}
                    />
                </If>
                <If condition={state.type === "holiday"}>
                    <HolidayControls
                        key="holiday-controls"
                        expiration={state.expirationTimestamp}
                        message={state.message}
                        name={state.title}
                        displayDate={state.displayDate}
                        partingPhrase={state.partingPhrase}
                        start={state.startTimestamp}
                        onExpirationChange={setExpiration}
                        onMessageChange={setMessage}
                        onNameChange={setTitle}
                        onStartChange={onStartChange}
                        onDisplayDateChange={onDisplayDateChange}
                        onPartingPhraseChange={onPartingPhraseChange}
                    />
                </If>
                <If condition={state.type === "banner"}>
                    <BannerControls
                        maxDescriptionLength={MAX_BANNER_DESCRIPTION_LENGTH}
                        backgroundColor={state.backgroundColor}
                        description={state.description}
                        expirationDate={state.expirationTimestamp}
                        message={state.message}
                        onBackgroundColorChange={onBackgroundColorChange}
                        onDescriptionChange={setDescription}
                        onExpirationDateChange={setExpiration}
                        onMessageChange={setMessage}
                        onStartDateChange={onStartChange}
                        startDate={state.startTimestamp}
                    />
                </If>
                <MaybeError error={error} />
            </form>
        </Dialog>
    );
}
