import * as Frontend from "kmmp/frontend";
import { BlobService } from "azure-storage";
import { CONSTANTS } from "client/constants";
import { uuid } from "shared/uuid";
import { compute, Option } from "@nozzlegear/railway";

// It seems like the browser files from the "azure-storage" npm package are broken and will throw errors when used.
// Plus, it is a *huge* JS file that's better cached on the CDN. So, while it's imported here, the build config
// actually sets it as an external and the server will load a pre-bundled one made by Microsoft alongside the client.
// https://github.com/Azure/azure-storage-node/blob/master/browser/README.md
// https://aka.ms/downloadazurestoragejs
import * as Azure from "azure-storage";

export class BlobUtility {
    get storageUri(): string {
        return `https://${CONSTANTS.AZURE_ACCOUNT}.blob.core.windows.net`;
    }

    /**
     * Creates a blob service configured with the @param customBlockSize and an infinite retry policy.
     */
    createBlobService: (customBlockSize: number) => BlobService = (customBlockSize) => {
        const blobService: BlobService = Azure.createBlobServiceWithSas(
            this.storageUri,
            CONSTANTS.AZURE_SHARED_ACCESS_SIGNATURE
        ).withFilter(new Azure.ExponentialRetryPolicyFilter());
        blobService.singleBlobPutThresholdInBytes = customBlockSize;

        return blobService;
    };
    /**
     * Gets a Blob object from an HTML canvas element.
     */
    getBlobFromCanvas: (canvas: HTMLCanvasElement) => Promise<Blob> = async (canvas) => {
        if ((canvas as any).msToBlob) {
            const blob = (canvas as any).msToBlob();

            return blob;
        }

        if (canvas.toBlob) {
            return new Promise<Blob>((res) => canvas.toBlob((blob) => res(blob!)));
        }

        // TODO: Attempt to use the MDN polyfill for getting a blob with base64
        const message =
            "Your browser does not support the necessary Canvas implementation. Please switch to a more modern browser such as Microsoft Edge, Firefox or Chrome to continue.";

        alert(message);

        throw new Error(message);
    };

    /**
     * Converts a blob object to a file-ish object. This is largely used to "polyfill" IE11, where you can't upload a blob via xhr but you can upload a file-ish object.
     */
    convertBlobToFile: (blob: Blob, fileName: string) => File = (blob, fileName) => {
        let b: any = blob;
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        b.lastModifiedDate = new Date();
        b.lastModified = b.lastModifiedDate.getTime();
        b.name = fileName;

        //Cast to a File() type
        return b as File;
    };

    /**
     * Splits a filename into its name and extension.
     */
    getFileNameParts: (name: string) => { name: string; ext: string } = (name) => {
        const parts = name.split(".");
        const ext = parts.pop();

        return {
            name: parts.join("."),
            ext: ext!,
        };
    };

    /**
     * Encodes filenames to a specific URL-safe scheme.
     */
    encodeFileName: (name: string) => string = (name) => {
        const parts = this.getFileNameParts(name);
        const replaced = parts.name
            // Replace all non-alphanumeric and non-underscore chars
            .replace(/\W/g, "-")
            // Replace all underscores
            .replace(/_/g, "-")
            // Replace any instances of 2 or more dashes with one dash
            .replace(/-{2,}/g, "--")
            // Lowercase it to make it consistent
            .toLowerCase();

        // And for a final bit of safety, encode whatever is left
        return `${encodeURIComponent(replaced)}.${parts.ext}`;
    };

    /**
     * Uploads an array of images to Azure blob storage.
     */
    uploadToAzure: (
        images: Frontend.PreviewImage[],
        onImageUploaded: (image: Frontend.PreviewImage) => void
    ) => Promise<Frontend.UploadedImage[]> = (images, onImageUploaded) =>
        Promise.all(
            images.map(async (i) => {
                // This function handles the upload of both the full image and cropped image (where applicable), and doesn't return until both are done
                const filenameParts = this.getFileNameParts(i.fileName);
                const uniqueName = `${filenameParts.name}-${uuid()}.${filenameParts.ext}`;

                // Upload the images
                const uploads: [
                    Promise<Frontend.UploadedImageDetails>,
                    Promise<Option<Frontend.UploadedImageDetails>>
                ] = [
                    this.uploadImage(i.fullImage.file, uniqueName),
                    compute<Promise<Option<Frontend.UploadedImageDetails>>>(async () => {
                        if (i.croppedImage) {
                            const croppedFile = this.convertBlobToFile(i.croppedImage.blob, i.fileName);
                            const croppedFilename = `cropped-${uniqueName}`;

                            return Option.ofSome(await this.uploadImage(croppedFile, croppedFilename));
                        }

                        return Option.ofNone();
                    }),
                ];

                // Wait for both images to upload before returning
                const [fullImage, croppedImage] = await Promise.all(uploads);

                onImageUploaded(i);

                const output: Frontend.UploadedImage = {
                    tooSmall: i.tooSmall,
                    fullImage: fullImage,
                    croppedImage: Option.isSome(croppedImage) ? Option.get(croppedImage) : null,
                    instructions: i.instructions || "",
                    uuid: i.uuid,
                };

                return output;
            })
        );

    uploadImage: (imageFile: File, uniqueFileName: string) => Promise<Frontend.UploadedImageDetails> = (
        imageFile,
        uniqueFileName
    ) =>
        new Promise<Frontend.UploadedImageDetails>((res, rej) => {
            const customBlockSize = imageFile.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
            const encodedFileName = this.encodeFileName(uniqueFileName);
            const service = this.createBlobService(customBlockSize);
            const creationOptions: BlobService.CreateBlockBlobRequestOptions = {
                blockSize: customBlockSize,
                contentSettings: {
                    contentType: imageFile.type,
                },
            };

            service.createBlockBlobFromBrowserFile(
                CONSTANTS.AZURE_IMAGES_CONTAINER,
                encodedFileName,
                imageFile,
                creationOptions,
                (error, result, response) => {
                    if (error) {
                        rej(error);
                    } else {
                        // While this script handles uploading to the full-images container, an Azure function will handle resizing the image to a thumbnail.
                        // Once resized, it will put the thumbnail in the thumbnail container with the same filename.
                        console.log("Done uploading image", {
                            result,
                            response,
                            filename: encodedFileName,
                        });
                        res({
                            fileName: encodedFileName,
                            srcUrl: `${this.storageUri}/${CONSTANTS.AZURE_IMAGES_CONTAINER}/${encodedFileName}`,
                            thumbnailUrl: `${this.storageUri}/${CONSTANTS.AZURE_THUMBS_CONTAINER}/${encodedFileName}`,
                        });
                    }
                }
            );
        });
}
