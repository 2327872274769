import * as Frontend from "kmmp/frontend";
import * as V2 from "kmmp/orders/v2";
import { ExhaustiveCheckError } from "./errors";

export function getShadowBoxDescription(
    customization: Frontend.ShadowBoxCustomization | V2.ShadowBoxCustomization
): string {
    const hasEngraving =
        !!customization.engravingName || !!customization.engravingDate1 || !!customization.engravingDate2;
    const withEngravingMessage = hasEngraving ? "with engraving" : "(no engraving)";

    return `${customization.color} Prestige Shadow Box ${withEngravingMessage}`;
}

export function getSignatureJerseyDescription(
    customization: Frontend.SignatureJerseyCustomization | V2.SignatureJerseyCustomization
): string {
    return `${customization.package} ${customization.frame} frame Prestige Jersey`;
}

export function getSignaturePortraitDescription(
    customization: Frontend.SignaturePortraitCustomization | V2.SignaturePortraitCustomization
): string {
    return `${customization.package} ${customization.frame.name} frame Prestige Portrait`;
}

export function getPortraitDescription(
    customization: Frontend.PortraitCustomization | V2.PortraitCustomization
): string {
    let withCardsMessage: string;

    switch (customization.package) {
        case "CP15":
        case "CP25":
        case "CP35":
            withCardsMessage = "5 portrait cards";
            break;

        default:
            withCardsMessage = "no cards";
            break;
    }

    const orientation = customization.orientation === "landscape" ? "horizontal" : "vertical";

    return `${customization.package} ${customization.frame.name} Portrait (${orientation}) with ${customization.background.name} background, ${withCardsMessage}`;
}

function getSampleFrameDescription(customization: Frontend.SampleFrameCustomization | V2.SampleFrameCustomization): string {
    switch (customization.type) {
        case "corners-only":
            return "Corners Only";
        case "frames-only":
            return "Sample Frames, no image";
        case "frames-with-sample-image":
            return "Sample Frames with generic image";
        case "frames-with-custom-image":
            return "Sample Frames with custom image";
        default:
            throw new ExhaustiveCheckError(customization);
    }
}

export function getLineItemDescription(lineItem: Frontend.LineItem | V2.LineItem): string {
    switch (lineItem.type) {
        case undefined:
        case "portrait":
            return getPortraitDescription(lineItem.customization);

        case "shadow-box":
            return getShadowBoxDescription(lineItem.customization);

        case "signature-jersey":
            return getSignatureJerseyDescription(lineItem.customization);

        case "signature-portrait":
            return getSignaturePortraitDescription(lineItem.customization);

        case "sample-frame":
            return getSampleFrameDescription(lineItem.customization);

        default:
            const neverItem: never = lineItem;
            console.error("Unhandled line item type", neverItem);
            throw new Error("Unhandled line item type.");
    }
}
